import React from "react";
import { Modal, } from 'antd';
// import { FormattedMessage, } from "react-intl";
import Calendar from 'react-calendar'
import '../../assets/styles/calendar.css';


const SloopSelectorDateModal = ({
    modalIsOpen,
    modalIsOpenHandler,
    setRentalDate,
    rentalDate,
    ...props
}) => {

    const setDate = (value) => {
        setRentalDate(value);
        modalIsOpenHandler(false);
    };

    return (
        <Modal
            open={modalIsOpen}
            centered={true}
            width={550}
            footer={[]}
            onCancel={() => modalIsOpenHandler(false)}>

            <section className="display-flex flex-center-center">
                <div className="bg-white padding-m radius-m">
                    <Calendar
                        minDetail="month"
                        minDate={new Date()}
                        value={rentalDate ? new Date(rentalDate) : undefined}
                        // value={'2024-06-01'}
                        onChange={setDate}
                        locale="nl-NL"
                    // formatDay={(locale, date) => date.getDate()}
                    />

                    {/* <div id="datum-kalendar" className="width-100">
                    </div> */}
                </div>
            </section>

        </Modal>
    );
};

export default SloopSelectorDateModal;
