import React, { useState, } from "react";
import { FormattedMessage, } from "react-intl";
import { CheckCircle, Info, CalendarDots, XCircle, } from '@phosphor-icons/react';

import Moment from 'react-moment';
import 'moment-timezone';
import moment from 'moment';
import { useLocalStorage } from 'usehooks-ts';

import ProductCardView from '../views/product.card.view';
import SloopSelectorDateModal from '../../../components/modals/sloop.selector.date.modal';
import Spinner from '../../../components/Spinner';


// Select the available boats
const HomeForm1 = ({
    productList,
    loadingData,
    history,
    categories,
    ...props
}) => {

    const [openDateModal, setOpenDateModal] = useState(false);
    // setting start date to UTC to prevent wrong date check for availability checker.
    const [rentalDate, setRentalDate] = useState(new Date(moment().startOf('day').utc().format()));
    const [shoppingCart] = useLocalStorage('shoppingcart_sloep', []);

    if (loadingData) {
        return (<Spinner />);
    }

    return (
        <React.Fragment>

            {/* Your benefits section */}
            <section className="column-container sloep-form-steps first-step">
                {/* <div className="column-container-wrapper">
                    <div
                        className="col-full display-flex flex-vertical-center gap-s note-card bg-secondary padding-s radius-m">
                        <CheckCircle size={20} className="white-color" />
                        <p className="content-description white-color phone-m-width-semifull">
                            <FormattedMessage id="step-1-information" />
                        </p>
                    </div>
                </div> */}

                <div className="column-container-wrapper">
                    <div className="col-full padding-tb-m border-bottom-solid margin-b-l mb-2">
                        <h2 className="content-title size-h4 primary-color weight-bold uppercase size-h4">
                            <FormattedMessage id="your-benefits" />
                        </h2>
                        <ul className="content-listing horizontal">
                            <li className="display-flex flex-vertical-center gap-s">
                                <CheckCircle size={20} className="primary-color flex-5p" />
                                <p className="content-description">
                                    <FormattedMessage id="free-cancel-info" />
                                </p>
                            </li>
                            <li className="display-flex flex-vertical-center gap-s">
                                <CheckCircle size={20} className="primary-color flex-5p" />
                                <p className="content-description">
                                    <FormattedMessage id="review-info" />
                                </p>
                            </li>

                            <li className="display-flex flex-vertical-center gap-s">
                                <CheckCircle size={20} className="primary-color flex-5p" />
                                <p className="content-description">
                                    <FormattedMessage id="experience-fun-and-comfort" />
                                </p>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>

            {/* Product section */}
            <section className="column-container sloep-form-steps second-step border-bottom-solid mb-2">

                {/* Header start */}
                <div className="column-container-wrapper">
                    <div className="col-full">
                        <h2
                            className="content-title weight-bold default-color uppercase margin-b-xxl phone-m-display-flex">
                            <span className="display-inline-flex flex-center-center border-rounder bg-secondary radius-round step-number-wrapper">
                                <i className="step-number">1</i>
                            </span>
                            <span className="default-color size-h2 weight-bold flex-auto flex-center-center">
                                <FormattedMessage id="reserve-your-boat" />
                            </span>
                        </h2>
                    </div>
                </div>

                {/* Date selector */}
                {productList.length > 0 && (
                    <div className="column-container-wrapper">
                        <div className="col-full display-flex">
                            <label className="label-form flex-1 margin-b-m">
                                <FormattedMessage id="choose-date" />
                            </label>
                            {/* <input
                            type="text"
                            name="datum" className="input-form flex-4 margin-b-xxl hide"
                            placeholder="datum" /> */}
                            <span id="second-step_datum_label"
                                onClick={e => { setOpenDateModal(true); }}
                                className="open-popup_btn flex-2 phone-l-width-full display-flex flex-vertical-center flex-between gap-s width-25 margin-b-xxl bg-white radius-s"
                                data-popup_id="datum-popup">
                                <span id="second-step_datum_value" className="padding-lr-xs">
                                    {rentalDate ? (
                                        <Moment tz="Europe/Amsterdam" format="DD-MM-YYYY" date={rentalDate} />
                                    ) : (
                                        <FormattedMessage id="choose-date" />
                                    )}
                                </span>
                                <i
                                    style={{ marginTop: 4 }}
                                    className="second-step__calendar_icon diplay-flex flex-center-center padding-lr-xs">
                                    <CalendarDots size={24} />
                                </i></span>
                        </div>
                    </div>
                )}

                {/* Form title */}
                {productList.length > 0 && (
                    <h3 className="content-title size-h4 primary-color weight-bold uppercase">
                        <FormattedMessage id="available-boats" />
                    </h3>
                )}

                {/* Check if productList is not empty */}
                {productList.length === 0 && (
                    <div className="column-container-wrapper">
                        <div
                            className="col-full display-flex flex-vertical-center gap-s note-card bg-primary padding-s radius-m">
                            <XCircle size={20} className="white-color rounded-icon" />
                            <p className="content-description white-color phone-m-width-semifull">
                                <FormattedMessage id="no-products-available" />
                            </p>
                        </div>
                    </div>
                )}

                {/* Product card containers */}
                <div
                    className="column-container-wrapper second-step__boat-availability display-flex flex-between gap-l margin-b-l">
                    {/* Map for products */}
                    {productList.map((item, index) => (
                        <React.Fragment key={index}>
                            <ProductCardView
                                data={item}
                                rentalDate={rentalDate}
                                categories={categories}
                                history={history}
                                {...props} />

                            {(index === 0 && productList.length > 1) && (
                                <h3 className="content-title size-h4 primary-color weight-bold uppercase hide show-flex-tablet">
                                    <FormattedMessage id="we-have-also-available" />
                                </h3>
                            )}
                        </React.Fragment>
                    ))}
                </div>

                {/* Info section about pricing */}
                <div
                    className="column-container-wrapper bg-faded-tertiary padding-s radius-l margin-t-xxl margin-b-xxl hide-tablet">
                    <div className="col-full display-flex note-card width-100 gap-m phone-m-display-flex">
                        <span className="flex-5p">
                            <Info size={20} className="bg-secondary white-color rounded-icon" />
                        </span>
                        <p className="content-description phone-m-width-semifull flex-auto">
                            <FormattedMessage id="all-rates-are-inclusive" />
                        </p>
                    </div>
                </div>

                {/* Next step button (only on desktop) */}
                <div className="column-container-wrapper second-step__cta-nav hide-tablet">
                    <div className="col-full display-flex align-right">
                        <button type="button"
                            onClick={e => history("/arrangementen")}
                            hidden={productList.length === 0 || shoppingCart.length === 0}
                            className="slv-button step__cta-nav-btn next-btn bg-secondary hover-bg-darkblue white-color weight-semibold margin-b-m next-nav-btn"
                        >
                            <FormattedMessage id="next-step" />
                        </button>
                    </div>
                </div>

            </section>

            {openDateModal && (
                <SloopSelectorDateModal
                    modalIsOpen={openDateModal}
                    modalIsOpenHandler={setOpenDateModal}
                    setRentalDate={setRentalDate}
                    rentalDate={rentalDate}
                />
            )}

        </React.Fragment>
    );
};

export default HomeForm1;