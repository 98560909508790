import React, { useEffect, } from "react";
import { useNavigate, } from 'react-router-dom';
// import { FormattedMessage, } from "react-intl";
import { Dropdown, Space, } from 'antd';
import { CaretDown, User, } from '@phosphor-icons/react';

import SloepLogo from './../../assets/images/sloep-logo.png';

import NLFlag from '../../assets/images/flags/nl.png';
import ENFlag from '../../assets/images/flags/en.png';
import DEFlag from '../../assets/images/flags/de.png';

const HeaderBar = ({
    counterShoppingcart = 0,
    setAuth = () => { },
    ...props
}) => {

    const { auth, language, setCurrentLanguage, branding_logo, } = props;
    const history = useNavigate();

    useEffect(() => {
        // getCategories();
        return () => {
            // cleanup
        }
        // below line needed to remove the warning on build.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setAuth(auth);

        // below line needed to remove the warning on build.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [auth]);

    // Language items
    const items = [
        {
            label: <><img className="language-flag-item" alt="Language EN" src={ENFlag} /> EN</>,
            key: 'en',
            onClick: (e) => { setCurrentLanguage(e.key); }
        },
        {
            label: <><img className="language-flag-item" alt="Language NL" src={NLFlag} /> NL</>,
            key: 'nl',
            onClick: (e) => { setCurrentLanguage(e.key); }
        },
        {
            label: <><img className="language-flag-item" alt="Language DE" src={DEFlag} /> DE</>,
            key: 'de',
            onClick: (e) => { setCurrentLanguage(e.key); }
        },
    ];


    return (

        <React.Fragment>

            <header id="sloepen-header-container" className="margin-b-xxl">
                {/* <div className="column-container-wrapper top-blue-container bg-color-darkblue width-100"></div> */}
                <div className="bg-white padding-lr-m padding-tb-s">
                    <div
                        className="column-container-wrapper page-container-content-wrapper display-flex flex-between flex-vertical-center">
                        <div className="col-left flex-auto">
                            <a href="/" className="display-flex">
                                <img
                                    src={branding_logo ? `https://${process.env.REACT_APP_AWS_DATASTORE}/${branding_logo}` : SloepLogo}
                                    id="logo-header"
                                    alt=""
                                />
                            </a>
                        </div>
                        <div className="col-right flex-70 display-flex flex-center-center">
                            <h2
                                className="content-title size-body-s uppercase weight-bold default-color text-align-right flex-auto margin-b-0">
                                {/* <FormattedMessage id="need-help" />? */}
                            </h2>

                            {/*  Profile / inloggen */}
                            <span className="flex-5p text-center pointer"
                                onClick={e => { history("/login"); history(0) }}>
                                <User size={22}
                                    style={{ marginTop: 7 }} />
                            </span>

                            <span className="flex-5p">
                                <Dropdown
                                    menu={{ items, }}>
                                    <div style={{ marginLeft: 5 }}>
                                        <Space className="text-uppercase">
                                            <ShowSelectedFlag language={language} />
                                            <CaretDown size={16} className="mr-1" />
                                        </Space>
                                    </div>
                                </Dropdown>
                            </span>
                        </div>
                    </div>
                </div>
            </header>

        </React.Fragment>
    );
};

const ShowSelectedFlag = ({ language }) => {
    switch (language) {
        case 'en':
            return <img className="language-flag-item" alt="Language EN" src={ENFlag} />;
        case 'nl':
            return <img className="language-flag-item" alt="Language NL" src={NLFlag} />;
        case 'de':
            return <img className="language-flag-item" alt="Language DE" src={DEFlag} />;
        default:
            return <img className="language-flag-item" alt="Language EN" src={ENFlag} />;
    }
}

export default HeaderBar;