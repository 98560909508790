
import React, { useState, } from "react";
import { Form, Input, notification, Row, Col } from 'antd';
import { LoadingOutlined, } from '@ant-design/icons';

import { FormattedMessage, useIntl } from "react-intl";
import { useForm, Controller } from "react-hook-form";
import { ChangePasswordForced } from '../../../services/users';
import { CaretLeft, LockSimple } from "@phosphor-icons/react";


const ChangePasswordFormView = ({
    modalIsOpenHandler,
    setShowForm,
    sessionData,
    setAuth,
    history,
}) => {

    const [buttonDisabled, setButtonDisabled] = useState(false);

    const intl = useIntl();
    const { handleSubmit, control, watch, } = useForm(
        { mode: "all" }, // set the field validation mode
    );

    const onSubmit = (data) => {
        setButtonDisabled(true);

        // set data for services
        const putdata = {
            data: sessionData,
            current_password: data.confirm_new_password,
            new_password: data.new_password,
            confirm_new_password: data.confirm_new_password
        }

        ChangePasswordForced(putdata)
            .then(response => {
                if (response.status) {
                    notification['success']({
                        message: 'Password',
                        description: intl.formatMessage({ id: response.msg }),
                    });
                    setAuth(response.data);
                    localStorage.setItem('authentication_sloep', JSON.stringify(response.data));
                    modalIsOpenHandler(false);

                    // history('/shoppingcart');

                } else {
                    setButtonDisabled(false);
                    notification['error']({
                        message: 'Change password',
                        description: intl.formatMessage({ id: response.msg }),
                    });

                };
            })
            .catch(error => {
                console.error('error: ', error);
                notification['error']({
                    message: 'Change password',
                    description: "An error occurred. Please check the browser console for more information",
                });

                setButtonDisabled(false);
            });
    };

    // handler for pressing the enter key
    const pressEnterKey = (event) => {
        var enterkey = event.keyCode || event.which;
        // check if enter button has pressed and the form is valid
        if (enterkey === 13) {
            handleSubmit(onSubmit)();
        };
    };

    return (
        <React.Fragment>

            <p><FormattedMessage id="forcechange-password-header" /></p>

            {/* new password field */}
            <FormattedMessage id="password">
                {NameField =>
                    <Controller
                        control={control}
                        defaultValue=""
                        name="new_password"
                        rules={{
                            required: { value: true, message: "inputfield-FieldIsRequired" },
                            minLength: { value: 8, message: "inputfield-minLength8" },
                            pattern: {
                                value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).+$/,
                                message: "PasswordMustContainsNumbersLettersUppercaseLowercase",
                            },
                        }}
                        render={({
                            field: { onChange, onBlur, value, name, ref },
                            fieldState: { error },
                        }) => (
                            <Form.Item
                                help={error && intl.formatMessage({ id: error?.message })}
                                hasFeedback
                                validateStatus={error?.message && "error"}
                                label={<strong>{NameField}</strong>}
                                required>
                                <Input.Password
                                    placeholder={NameField}
                                    autoComplete="off"
                                    prefix={<LockSimple size={18} weight="light" className="site-form-item-icon" />}
                                    onChange={onChange}
                                    value={value}
                                />
                            </Form.Item>
                        )}
                    />
                }
            </FormattedMessage>

            {/* repeat password field */}
            <FormattedMessage id="repeatpassword">
                {NameField =>
                    <Controller
                        control={control}
                        defaultValue=""
                        name="confirm_new_password"
                        rules={{
                            required: { value: true, message: "inputfield-FieldIsRequired" },
                            minLength: { value: 8, message: "inputfield-minLength8" },
                            pattern: {
                                value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).+$/,
                                message: "PasswordMustContainsNumbersLettersUppercaseLowercase",
                            },

                            // pattern: {
                            //     value: /^(?=.*[a-zA-Z])(?=.*\d).+$/,
                            //     message: "PasswordMustContainsNumbersLetters",
                            // },
                            validate: {
                                compare: value => {
                                    if (watch('new_password') !== value) {
                                        return 'PasswordsNotEqual';
                                    } else {
                                        return undefined;
                                    };
                                },
                            }
                        }}
                        render={({
                            field: { onChange, onBlur, value, name, ref },
                            fieldState: { error },
                        }) => (
                            <Form.Item
                                help={error && intl.formatMessage({ id: error?.message })}
                                hasFeedback
                                validateStatus={error?.message && "error"}
                                required
                                label={<strong>{NameField}</strong>}>
                                <Input.Password
                                    placeholder={NameField}
                                    onChange={onChange}
                                    value={value}
                                    autoComplete="off"
                                    onPressEnter={pressEnterKey}
                                    prefix={<LockSimple size={18} weight="light" className="site-form-item-icon" />}
                                />
                            </Form.Item>
                        )}
                    />}
            </FormattedMessage>

            <div className="text-center mt-4">
                <div className="btn__box">
                    <button className="slv-button white-color weight-semibold bg-secondary pointer"
                        onClick={handleSubmit(onSubmit)}
                        disabled={buttonDisabled}>
                        <span className="txt text-center">
                            {buttonDisabled ? (
                                <LoadingOutlined />) : (
                                <FormattedMessage id="save-password" />
                            )}
                        </span>
                    </button>
                </div>
            </div>

            <Row className="mt-3 login-footer">
                <Col span={24}><CaretLeft size={18} weight="light" /> <span className="pointer" onClick={e => setShowForm("LOGIN")}><FormattedMessage id="back" /></span></Col>
            </Row>

        </React.Fragment>
    );
};

export default ChangePasswordFormView;