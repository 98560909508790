import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';

// import './assets/styles/bootstrap.css';
// import './assets/styles/flaticon_xyz.css';
import './assets/styles/style.css';
import './assets/styles/custom.css';
import './assets/styles/spinner.style.css';

// Require Editor CSS files.
// import 'react-quill/dist/quill.snow.css';

const container = document.getElementById('root');
const root = createRoot(container);

/*
orange: #ff7100
paars: #46005c
*/

root.render(<React.StrictMode><App /></React.StrictMode>);
// root.render(<App />);
