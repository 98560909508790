import ReactGA from "react-ga4";
// import TagManager from 'react-gtm-module';

export const SERVER = process.env.REACT_APP_SERVER;

export const GApageView = (page) => {
    // ReactGA.send(page);
    ReactGA.send({ hitType: "pageview", page: page });
};

export const GAevent = (category, action) => {
    ReactGA.event({
        category: category,
        action: action,
        // label: "your label", // optional
        // value: 99, // optional, must be a number
        // nonInteraction: true, // optional, true/false
        // transport: "xhr", // optional, beacon/xhr/image
    });
};

export const GAPurchaseEvent = (data) => {
    ReactGA.event('purchase', data);
    //     {
    //     category: 'Ecommerce',
    //     action: 'purchase'
    // },
    // data,
    // );
};

// eslint-disable-next-line no-extend-native
Date.prototype.addYears = function (years) {
    var date = new Date(this.valueOf());
    date.setFullYear(date.getFullYear() + years)
    return date;
};

// eslint-disable-next-line no-extend-native
Date.prototype.addDays = function (days) {
    var date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
};

// eslint-disable-next-line no-extend-native
Date.prototype.addHours = function (hours) {
    var date = new Date(this.valueOf());
    date.setHours(date.getHours() + hours);
    return date;
};

// eslint-disable-next-line no-extend-native
Date.prototype.addMinutes = function (minutes) {
    var date = new Date(this.valueOf());
    date.setMinutes(date.getMinutes() + minutes);
    return date;
};