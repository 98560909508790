import React, { useState, useEffect, } from "react";
import { Modal, Button, Radio, Form, Input, Card, notification, Select, } from 'antd';
import { FormattedMessage, useIntl, } from "react-intl";
import { useForm, Controller, } from "react-hook-form";
import { useNavigate, useLocation, } from "react-router-dom";
import { COUNTRIES, } from '../../../../../constants/countries';  // default for Netherlands = nl
import { CustomerUpdate, } from '../../../../../services/customer';
import { ArrowUp, CheckSquare, Pen, X, } from "@phosphor-icons/react";

// New version
const CustomerEditModal = ({
    modalIsOpen,
    modalIsOpenHandler,
    customerData,
    refreshData,
}) => {
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [hideProfileFields, setHideProfileFields] = useState(true);
    // const [experiences, setExperiences] = useState([]);
    // const [loadingData, setLoadingData] = useState(true);

    const { handleSubmit, reset, control, formState, watch, } = useForm({ mode: 'onChange' });
    const intl = useIntl();
    const history = useNavigate();
    const { pathname } = useLocation();
    const customerType = watch("customer_type");

    useEffect(() => {
        // setup correct data object
        const formdata = {
            "customer_name": customerData.customer_name,
            "customer_vat_number": customerData.customer_data?.customer_vat_number,
            "customer_registration_number": customerData.customer_data?.customer_registration_number,
            "customer_phone_number": customerData.customer_data?.customer_contact_data?.customer_phone_number,
            "customer_email": customerData.customer_data?.customer_contact_data?.customer_email,
            "customer_note": customerData.customer_note,
            "city": customerData?.customer_data?.customer_address?.city,
            "country": customerData?.customer_data?.customer_address?.country || 'nl',
            "street_number": customerData?.customer_data?.customer_address?.street_number,
            "zip_code": customerData?.customer_data?.customer_address?.zip_code,
            "customer_type": customerData?.customer_type,
        };
        reset(formdata);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (pathname === '/profile') {
            setHideProfileFields(true);
        } else {
            setHideProfileFields(false);
        }

        return () => {
            // cleanup
        }
        // below line needed to remove the warning on build.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customerData, pathname])

    const onSubmit = (data) => {
        setButtonDisabled(true);
        const postdata = {
            "customer_id": customerData.customer_id,
            "customer_type": customerType,
            "customer_name": data.customer_name,
            "street_number": data.street_number,
            "zip_code": data.zip_code,
            "city": data.city,
            "country": data.country,
            "customer_registration_number": data.customer_registration_number,
            "customer_vat_number": data.customer_vat_number,
            "customer_phone_number": data.customer_phone_number,
            "customer_email": data.customer_email,
            "customer_note": data.customer_note,
        };

        CustomerUpdate(postdata, history)
            .then(response => {
                if (response.status) {
                    notification['success']({
                        message: 'Update',
                        description: intl.formatMessage({ id: response.msg }),
                    });

                    //close modal
                    modalIsOpenHandler(false);
                    refreshData();
                } else {
                    notification['error']({
                        message: 'Update',
                        description: intl.formatMessage({ id: response.msg }),
                    });
                    setButtonDisabled(false);
                }
            })
            .catch(error => {
                console.error('error: ', error);
                if (error.status === 403) {
                    history('/dashboard');
                    notification['error']({
                        message: 'Account',
                        description: intl.formatMessage({ id: "ENDPOINT_ACCESS_DENIED" }),
                    });
                }
                setButtonDisabled(false);
            });
    };

    const FormError = () => {
        return (<>
            <ArrowUp size={18} weight="light" className="mr-1" /> <span>Form contains error's</span>
        </>)
    };

    const options = [
        {
            label: "Consument",
            value: "CONSUMER"
        },
        {
            label: "Bedrijf",
            value: "COMPANY"
        },
    ];


    const filterOption = (input, option) => {
        return option.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
            option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    };

    return (

        <Modal
            open={modalIsOpen}
            title={<><Pen size={18} weight="light" className="mr-1" /> <FormattedMessage id="customer-edit-details" /></>}
            onCancel={() => modalIsOpenHandler(false)}
            width={750}
            className="user"
            footer={[
                <span key="0" className="mr-4" style={{ fontWeight: "700", color: "red" }}>{!formState.isValid && <FormError />}</span>,
                <Button key="1" onClick={() => modalIsOpenHandler(false)}>
                    <span><X size={18} weight="light" className="mr-1" /> <FormattedMessage id="cancel" /></span>
                </Button>,
                <Button key="2"
                    className="ant-btn-success"
                    loading={buttonDisabled}
                    onClick={handleSubmit(onSubmit)}>
                    <span>{!buttonDisabled && (<CheckSquare size={18} weight="light" className="mr-1" />)} <FormattedMessage id="save" /></span>
                </Button>,
            ]}>

            {/* Freelancer basic details card */}
            <Card title={<FormattedMessage id="customer-edit-details-basic" />}>
                <Form
                    labelCol={{ span: 10 }}
                    wrapperCol={{ span: 14 }}>

                    {/* Choose company or cusumer */}
                    <FormattedMessage id="customer-company">
                        {NameField =>
                            <Controller
                                control={control}
                                defaultValue={false}
                                name="customer_type"
                                rules={{}}
                                render={({
                                    field: { onChange, onBlur, value, name, ref },
                                    fieldState: { error },
                                }) => (
                                    <Form.Item
                                        label={<strong>{NameField}</strong>}>
                                        <Radio.Group
                                            options={options}
                                            onChange={onChange}
                                            value={value}
                                            optionType="button"
                                            buttonStyle="solid"
                                        />
                                    </Form.Item>
                                )}
                            />}
                    </FormattedMessage>

                    {/* company / cosumer fields*/}

                    {customerType === "COMPANY" ? (

                        <React.Fragment>
                            <FormattedMessage id="company-name">
                                {NameField =>
                                    <Controller
                                        control={control}
                                        defaultValue=""
                                        name="customer_name"
                                        rules={{ required: { value: true, message: "inputfield-FieldIsRequired" } }}
                                        render={({
                                            field: { onChange, onBlur, value, name, ref },
                                            fieldState: { error },
                                        }) => (
                                            <Form.Item
                                                help={error && intl.formatMessage({ id: error?.message })}
                                                hasFeedback
                                                validateStatus={error?.message && "error"}
                                                required
                                                label={<strong>{NameField}</strong>}>
                                                <Input onChange={onChange}
                                                    value={value}
                                                    placeholder={NameField}
                                                />
                                            </Form.Item>
                                        )}
                                    />
                                }
                            </FormattedMessage>

                            {/* company registration number */}
                            <FormattedMessage id="company-registration-number">
                                {NameField =>
                                    <Controller
                                        control={control}
                                        defaultValue=""
                                        name="customer_registration_number"
                                        // rules={{ required: { value: true, message: "inputfield-FieldIsRequired" } }}
                                        render={({
                                            field: { onChange, onBlur, value, name, ref },
                                            fieldState: { error },
                                        }) => (
                                            <Form.Item
                                                help={error && intl.formatMessage({ id: error?.message })}
                                                hasFeedback
                                                validateStatus={error?.message && "error"}
                                                label={<strong>{NameField}</strong>}>
                                                <Input type="number" onChange={onChange}
                                                    value={value}
                                                    placeholder={NameField}
                                                />
                                            </Form.Item>
                                        )}
                                    />
                                }
                            </FormattedMessage>

                            {/* Company VAT number */}
                            <FormattedMessage id="company-vat-number">
                                {NameField =>
                                    <Controller
                                        control={control}
                                        defaultValue=""
                                        name="customer_vat_number"
                                        // rules={{ required: { value: true, message: "inputfield-FieldIsRequired" } }}
                                        render={({
                                            field: { onChange, onBlur, value, name, ref },
                                            fieldState: { error },
                                        }) => (
                                            <Form.Item
                                                help={error && intl.formatMessage({ id: error?.message })}
                                                hasFeedback
                                                validateStatus={error?.message && "error"}
                                                label={<strong>{NameField}</strong>}>
                                                <Input type="number"
                                                    onChange={onChange}
                                                    value={value}
                                                    placeholder={NameField}
                                                />
                                            </Form.Item>
                                        )}
                                    />
                                }
                            </FormattedMessage>
                        </React.Fragment>
                    ) : (
                        <FormattedMessage id="customer-name">
                            {NameField =>
                                <Controller
                                    control={control}
                                    defaultValue=""
                                    name="customer_name"
                                    rules={{ required: { value: true, message: "inputfield-FieldIsRequired" } }}
                                    render={({
                                        field: { onChange, onBlur, value, name, ref },
                                        fieldState: { error },
                                    }) => (
                                        <Form.Item
                                            help={error && intl.formatMessage({ id: error?.message })}
                                            hasFeedback
                                            validateStatus={error?.message && "error"}
                                            required
                                            label={<strong>{NameField}</strong>}>
                                            <Input onChange={onChange}
                                                value={value}
                                                placeholder={NameField}
                                            />
                                        </Form.Item>
                                    )}
                                />
                            }
                        </FormattedMessage>
                    )}
                </Form>
            </Card>

            {/* Customer address card */}
            <Card className="mt-4" title={<FormattedMessage id="customer-edit-details-address" />}>
                <Form
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}>

                    {/* Address */}
                    <FormattedMessage id="customer-street-number">
                        {NameField =>
                            <Controller
                                control={control}
                                defaultValue=""
                                name="street_number"
                                rules={{ required: { value: true, message: "inputfield-FieldIsRequired" } }}
                                render={({
                                    field: { onChange, onBlur, value, name, ref },
                                    fieldState: { error },
                                }) => (
                                    <Form.Item
                                        help={error && intl.formatMessage({ id: error?.message })}
                                        hasFeedback
                                        validateStatus={error?.message && "error"}
                                        required
                                        label={<strong>{NameField}</strong>}>
                                        <Input
                                            placeholder={NameField}
                                            onChange={onChange}
                                            value={value}
                                        />
                                    </Form.Item>
                                )}
                            />
                        }
                    </FormattedMessage>

                    {/* zipcode and city form */}
                    <Form.Item
                        label={<strong><FormattedMessage id="customer-zip-city" /></strong>}
                        required
                        style={{ marginBottom: 0 }}>
                        {/* zipcode form */}
                        <FormattedMessage id="zip-code">
                            {NameField =>
                                <Controller
                                    control={control}
                                    defaultValue=""
                                    name="zip_code"
                                    rules={{ required: { value: true, message: "inputfield-FieldIsRequired" } }}
                                    render={({
                                        field: { onChange, onBlur, value, name, ref },
                                        fieldState: { error },
                                    }) => (
                                        <Form.Item
                                            help={error && intl.formatMessage({ id: error?.message })}
                                            hasFeedback
                                            validateStatus={error?.message && "error"}
                                            required
                                            style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}
                                        // label={<FormattedMessage id="firstname-and-lastname" />}
                                        >
                                            <Input
                                                placeholder={NameField}
                                                onChange={onChange}
                                                value={value}
                                            />
                                        </Form.Item>
                                    )}
                                />
                            }
                        </FormattedMessage>

                        {/* city form */}
                        <FormattedMessage id="city">
                            {NameField =>
                                <Controller
                                    control={control}
                                    defaultValue=""
                                    name="city"
                                    rules={{ required: { value: true, message: "inputfield-FieldIsRequired" } }}
                                    render={({
                                        field: { onChange, onBlur, value, name, ref },
                                        fieldState: { error },
                                    }) => (
                                        <Form.Item
                                            help={error && intl.formatMessage({ id: error?.message })}
                                            hasFeedback
                                            validateStatus={error?.message && "error"}
                                            required
                                            style={{ display: 'inline-block', width: 'calc(50% - 8px)', margin: '0 8px' }}>
                                            <Input
                                                placeholder={NameField}
                                                onChange={onChange}
                                                value={value}
                                            />
                                        </Form.Item>
                                    )}
                                />
                            }
                        </FormattedMessage>
                    </Form.Item>

                    {/* Country */}
                    <FormattedMessage id="country">
                        {NameField =>
                            <Controller
                                control={control}
                                defaultValue="nl"
                                name="country"
                                rules={{ required: { value: true, message: "inputfield-FieldIsRequired" } }}
                                render={({
                                    field: { onChange, onBlur, value, name, ref },
                                    fieldState: { error },
                                }) => (
                                    <Form.Item
                                        help={error && intl.formatMessage({ id: error?.message })}
                                        hasFeedback
                                        validateStatus={error?.message && "error"}
                                        label={<strong>{NameField}</strong>}
                                        required>
                                        <Select
                                            showSearch
                                            filterOption={filterOption}
                                            value={value}
                                            onChange={(e) => { onChange(e) }}>
                                            <Select.Option value="">{intl.formatMessage({ id: 'make-choice' })}</Select.Option>

                                            {COUNTRIES.map(title => (
                                                <Select.Option key={title.code} value={title.code}>
                                                    {title.name}
                                                </Select.Option>
                                            ))}

                                        </Select>
                                    </Form.Item>
                                )}
                            />
                        }
                    </FormattedMessage>

                </Form>
            </Card>

            {/* customer common contact card */}
            <Card className="mt-4" title={<FormattedMessage id="edit-contact-details" />}>
                <Form
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}>

                    {/* phone number */}
                    <FormattedMessage id="phonenumber">
                        {NameField =>
                            <Controller
                                control={control}
                                defaultValue=""
                                name="customer_phone_number"
                                rules={{ required: { value: true, message: "inputfield-FieldIsRequired" } }}
                                render={({
                                    field: { onChange, onBlur, value, name, ref },
                                    fieldState: { error },
                                }) => (
                                    <Form.Item
                                        help={error && intl.formatMessage({ id: error?.message })}
                                        hasFeedback
                                        validateStatus={error?.message && "error"}
                                        required
                                        label={<strong>{NameField}</strong>}>
                                        <Input
                                            placeholder={NameField}
                                            onChange={onChange}
                                            value={value}
                                        />
                                    </Form.Item>
                                )}
                            />
                        }
                    </FormattedMessage>

                    <FormattedMessage id="emailaddress">
                        {NameField =>
                            <Controller
                                control={control}
                                defaultValue=""
                                name="customer_email"
                                rules={{
                                    required: { value: true, message: "inputfield-FieldIsRequired" },
                                    pattern: {
                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,24}$/i,
                                        message: "EmailAddressNotValid",
                                    },
                                }}
                                render={({
                                    field: { onChange, onBlur, value, name, ref },
                                    fieldState: { error },
                                }) => (
                                    <Form.Item
                                        help={error && intl.formatMessage({ id: error?.message })}
                                        hasFeedback
                                        validateStatus={error?.message && "error"}
                                        label={<strong>{NameField}</strong>}
                                        required>
                                        <Input
                                            placeholder={NameField}
                                            onChange={onChange}
                                            value={value}
                                        // prefix={<MailOutlined className="site-form-item-icon" />}
                                        // onChange={(e) => { props.onChange(e) }}
                                        // checked={props.value}
                                        />
                                    </Form.Item>
                                )}
                            />
                        }
                    </FormattedMessage>

                </Form>
            </Card>

            {/* customer additional card */}
            {hideProfileFields ? (null) : (
                <Card className="mt-4" title={<FormattedMessage id="customer-edit-details-additional" />}>
                    <Form
                        labelCol={{ span: 8 }}
                        wrapperCol={{ span: 16 }}>

                        {/* customer note card */}
                        {hideProfileFields ? (null) : (
                            <FormattedMessage id="notes">
                                {NameField =>
                                    <Controller
                                        control={control}
                                        defaultValue=""
                                        name="customer_note"
                                        // rules={{ required: { value: true, message: "inputfield-FieldIsRequired" } }}
                                        render={({
                                            field: { onChange, onBlur, value, name, ref },
                                            fieldState: { error },
                                        }) => (
                                            <Form.Item
                                                help={error && intl.formatMessage({ id: error?.message })}
                                                hasFeedback
                                                validateStatus={error?.message && "error"}
                                                // required
                                                label={<strong>{NameField}</strong>}>

                                                <Input.TextArea
                                                    placeholder={NameField}
                                                    onChange={onChange}
                                                    value={value}
                                                />
                                            </Form.Item>
                                        )}
                                    />
                                }
                            </FormattedMessage>
                        )}
                    </Form>
                </Card>
            )}


        </Modal>
    );
};

export default CustomerEditModal;