const CustomCSS = ({
    ...props

}) => {
    const { branding_color_header, branding_color_primary, branding_color_hover, } = props.themeData;

    var style = document.createElement('style');
    style.id = "CustomCSS";
    style.setAttribute('nonce', `${process.env.REACT_APP_GENERATED_NONCE_VALUE}`);
    style.innerHTML = `
        a:nonce{content:${process.env.REACT_APP_GENERATED_NONCE_VALUE}}
        a:hover{color: ${branding_color_hover} !important}
        .page__title:before {background: ${branding_color_header}}
        .other__options__add .btn__box button { border-color: ${branding_color_primary}; color: ${branding_color_primary}; }
        .cart__button__block a.btn-two {border-color: ${branding_color_primary};color: ${branding_color_primary}}
        .your__cart .cart__img:before { background: ${branding_color_primary}}
        .billing-info .bottom__button p { color: ${branding_color_primary}}
        .tab__btn.active-btn,
        .ant-tabs-tab.ant-tabs-tab-active,
        .ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
            color: ${branding_color_primary} !important;
            border-bottom-color: ${branding_color_primary};
        }
        .ant-tabs .ant-tabs-ink-bar {background: ${branding_color_primary} !important}
        .data__list h5 {color: ${branding_color_primary}}
        .switch input[type="checkbox"]:checked {background: ${branding_color_primary}}
        p.hide__boday {color: ${branding_color_primary}}
        .product__title span {background: ${branding_color_primary}}
        .product__icon ul li a i, .product__icon ul li  i {color: ${branding_color_primary}}
        .banner__content__right .banner__right:before {border-color: ${branding_color_primary}}
        .header__right .icon__list ul li:nth-child(4):before,
        .header__right .icon__list ul li:nth-child(3):before {background: ${branding_color_primary}}
        .contact__left figure.image:before {border-color: ${branding_color_primary}}
        .title h2:before { background: ${branding_color_primary}}
        .ant-badge .ant-badge-count {background: ${branding_color_primary}}
        .map__block .contact__info .icon__left i {color: ${branding_color_primary}}
        .map__block .contact__info .icon__left svg {color: ${branding_color_primary}}
        .preloader .loader .ytp-spinner-circle { border-color: ${branding_color_primary} ${branding_color_hover} #e9ecef}
    `;

    // check if element has already created.
    var myEle = document.getElementById("CustomCSS");


    if (!myEle) {
        // var myEleValue= myEle.value;
        document.getElementsByTagName('head')[0].appendChild(style);
    }

    return null;
};

export default CustomCSS;