import React from "react";
import { Modal, } from 'antd';
// import { FormattedMessage, } from "react-intl";
import { Image, } from "antd";
import PlaceHolder from '../../assets/images/placeholder.png';


const ArrangementInfoModal = ({
    modalIsOpen,
    modalIsOpenHandler,
    productData,
    ...props
}) => {

    const { language } = props;


    const renderHTMLContent = (htmlContent) =>
        React.createElement('div', {
            dangerouslySetInnerHTML: { __html: htmlContent },
        });

    return (
        <Modal
            open={modalIsOpen}
            centered={true}
            width={550}
            footer={[]}
            onCancel={() => modalIsOpenHandler(false)}>

            <section class="column-container display-flex flex-center-center">
                <div class="column-container-wrapper bg-white">
                    <h2 class="content-title default-color weight-bold margin-b-l">
                        {productData.product_names[`product_name_${language}`] || productData.product_names.product_name_nl}
                    </h2>
                    <div class="content-description margin-b-l">
                        {renderHTMLContent(productData.product_descriptions[`product_description_${language}`] || productData.product_descriptions.product_description_nl)}
                    </div>

                    <Image src={productData.product_images[0]?.image_s3_key ? `https://${process.env.REACT_APP_AWS_DATASTORE}/${productData.product_images[0].image_s3_key}` : PlaceHolder}
                        // width={100}
                        // preview={false}
                        // style={{ display: "inline" }}
                        // rootClassName="product-cover-image"
                        className="radius-m _xlarge"
                    />

                </div>
            </section>

        </Modal>
    );
};

export default ArrangementInfoModal;
