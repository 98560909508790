import React, { useState, } from "react";
import { Modal, Result, Button, } from 'antd';
import { FormattedMessage, useIntl, } from "react-intl";
import { Controller, useForm, } from "react-hook-form";
import * as api from '../../services/api';


const SignupWaitingListModal = ({
    modalIsOpen,
    modalIsOpenHandler,
    history,
    ...props
}) => {

    const [contactSuccess, setContactSuccess] = useState(false);
    const { handleSubmit, control, } = useForm({ mode: 'onChange', });
    const { GAevent, } = props;
    const intl = useIntl();

    const onFormSubmit = (data) => {
        const post_data = {
            firstname: data.name,
            email: data.email,
            lastname: data.name,
            phone: data.phone,
            comments: `Gewenste dag: ${data.desired_day}, gewenste tijd: ${data.desired_time}, gewenste vaartijd: ${data.desired_trip_time}`
        };

        GAevent('CTA', "Waintinglist succesfull submited");
        api.post('/mailler/contact', post_data).then(response => {
            if (response.data.status) {
                setContactSuccess(true);
            }
        });

    };

    return (
        <Modal
            open={modalIsOpen}
            centered={true}
            width={650}
            footer={[]}
            onCancel={() => modalIsOpenHandler(false)}>

            <section class="column-container display-flex flex-center-center">
                {!contactSuccess && (
                    <div class="column-container-wrapper bg-white padding-l radius-m">
                        <div class="width-100">

                            {/* Header */}
                            <h2 class="content-title weight-bold default-color uppercase">
                                <FormattedMessage id="add-yourself-to-reserve-list" />
                            </h2>
                            <p class="content-description margin-b-xxl">
                                <FormattedMessage id="add-yourself-to-reserve-list-info" />
                            </p>

                            {/* Form field name */}
                            <h3 class="content-title size-body weight-regular default-color">
                                <FormattedMessage id="name" />
                            </h3>
                            <FormattedMessage id="name">
                                {NameField =>
                                    <Controller
                                        control={control}
                                        defaultValue=""
                                        name="name"
                                        rules={{
                                            required: { value: true, message: "inputfield-FieldIsRequired" },
                                            // minLength: { value: 8, message: "inputfield-minLength8" },
                                            // pattern: {
                                            //     value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).+$/,
                                            //     message: "PasswordMustContainsNumbersLettersUppercaseLowercase",
                                            // },
                                        }}
                                        render={({
                                            field: { onChange, onBlur, value, name, ref },
                                            fieldState: { error },
                                        }) => (
                                            <div className="personen__inputs-wrapper display-flex margin-b-xl"
                                                // validateStatus={error?.message && "error"}
                                                label={<strong>{NameField}</strong>}
                                                required>
                                                <input
                                                    placeholder={NameField}
                                                    autoComplete="off"
                                                    onChange={onChange}
                                                    value={value}
                                                    className="input-form weight-regular flex-1 calc-gap-l phone-l-width-full"
                                                />
                                                {error && (<p>{intl.formatMessage({ id: error?.message })}</p>)}
                                            </div>
                                        )}
                                    />
                                }
                            </FormattedMessage>

                            {/* Email form */}
                            <h3 class="content-title size-body weight-regular default-color">
                                <FormattedMessage id="email" />
                            </h3>
                            <FormattedMessage id="email">
                                {NameField =>
                                    <Controller
                                        control={control}
                                        defaultValue=""
                                        name="email"
                                        rules={{
                                            required: { value: true, message: "inputfield-FieldIsRequired" },
                                            pattern: {
                                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,24}$/i,
                                                message: "EmailAddressNotValid",
                                            },
                                        }}
                                        render={({
                                            field: { onChange, onBlur, value, name, ref },
                                            fieldState: { error },
                                        }) => (
                                            <div className="personen__inputs-wrapper display-flex margin-b-xl"
                                                // validateStatus={error?.message && "error"}
                                                required>
                                                <input
                                                    placeholder={NameField}
                                                    autoComplete="off"
                                                    onChange={onChange}
                                                    value={value}
                                                    className="input-form weight-regular flex-1 calc-gap-l phone-l-width-full"
                                                />
                                                {error && (<p>{intl.formatMessage({ id: error?.message })}</p>)}
                                            </div>
                                        )}
                                    />
                                }
                            </FormattedMessage>

                            {/* Field form phone */}
                            <h3 class=" content-title size-body weight-regular default-color">
                                <FormattedMessage id="phone" />
                            </h3>
                            <FormattedMessage id="phone">
                                {NameField =>
                                    <Controller
                                        control={control}
                                        defaultValue=""
                                        name="phone"
                                        rules={{
                                            required: { value: true, message: "inputfield-FieldIsRequired" },
                                        }}
                                        render={({
                                            field: { onChange, onBlur, value, name, ref },
                                            fieldState: { error },
                                        }) => (
                                            <div className="personen__inputs-wrapper display-flex margin-b-xl"
                                                // validateStatus={error?.message && "error"}
                                                required>
                                                <input
                                                    placeholder={NameField}
                                                    autoComplete="off"
                                                    onChange={onChange}
                                                    value={value}
                                                    className="input-form weight-regular flex-1 calc-gap-l phone-l-width-full"
                                                />
                                                {error && (<p>{intl.formatMessage({ id: error?.message })}</p>)}
                                            </div>
                                        )}
                                    />
                                }
                            </FormattedMessage>

                            {/* desired-day field */}
                            <h3 class="content-title size-body weight-regular default-color">
                                <FormattedMessage id="desired-day" />
                            </h3>
                            <FormattedMessage id="desired-day">
                                {NameField =>
                                    <Controller
                                        control={control}
                                        defaultValue=""
                                        name="desired_day"
                                        rules={{
                                            required: { value: true, message: "inputfield-FieldIsRequired" },
                                        }}
                                        render={({
                                            field: { onChange, onBlur, value, name, ref },
                                            fieldState: { error },
                                        }) => (
                                            <div className="personen__inputs-wrapper display-flex margin-b-xl"
                                                // validateStatus={error?.message && "error"}
                                                required>
                                                <input
                                                    placeholder={NameField}
                                                    autoComplete="off"
                                                    onChange={onChange}
                                                    value={value}
                                                    className="input-form weight-regular flex-1 calc-gap-l phone-l-width-full"
                                                />
                                                {error && (<p>{intl.formatMessage({ id: error?.message })}</p>)}
                                            </div>
                                        )}
                                    />
                                }
                            </FormattedMessage>

                            {/* desired-time field */}
                            <h3 class="content-title size-body weight-regular default-color">
                                <FormattedMessage id="desired-time" />
                            </h3>
                            <FormattedMessage id="desired-time">
                                {NameField =>
                                    <Controller
                                        control={control}
                                        defaultValue=""
                                        name="desired_time"
                                        rules={{
                                            required: { value: true, message: "inputfield-FieldIsRequired" },
                                        }}
                                        render={({
                                            field: { onChange, onBlur, value, name, ref },
                                            fieldState: { error },
                                        }) => (
                                            <div className="personen__inputs-wrapper display-flex margin-b-xl"
                                                // validateStatus={error?.message && "error"}
                                                required>
                                                <input
                                                    placeholder={NameField}
                                                    autoComplete="off"
                                                    onChange={onChange}
                                                    value={value}
                                                    className="input-form weight-regular flex-1 calc-gap-l phone-l-width-full"
                                                />
                                                {error && (<p>{intl.formatMessage({ id: error?.message })}</p>)}
                                            </div>
                                        )}
                                    />
                                }
                            </FormattedMessage>

                            {/* desired-trip-time field */}
                            <h3 class="content-title size-body weight-regular default-color">
                                <FormattedMessage id="desired-trip-time" />
                            </h3>
                            <FormattedMessage id="desired-trip-time">
                                {NameField =>
                                    <Controller
                                        control={control}
                                        defaultValue=""
                                        name="desired_trip_time"
                                        rules={{
                                            required: { value: true, message: "inputfield-FieldIsRequired" },
                                        }}
                                        render={({
                                            field: { onChange, onBlur, value, name, ref },
                                            fieldState: { error },
                                        }) => (
                                            <div className="personen__inputs-wrapper display-flex margin-b-xl"
                                                // validateStatus={error?.message && "error"}
                                                required>
                                                <input
                                                    placeholder={NameField}
                                                    autoComplete="off"
                                                    onChange={onChange}
                                                    value={value}
                                                    className="input-form weight-regular flex-1 calc-gap-l phone-l-width-full"
                                                />
                                                {error && (<p>{intl.formatMessage({ id: error?.message })}</p>)}
                                            </div>
                                        )}
                                    />
                                }
                            </FormattedMessage>

                        </div>

                        <div class="col-full display-flex flex-center-center">
                            <button
                                onClick={handleSubmit(onFormSubmit)}
                                type="button" class="slv-button bg-secondary hover-bg-darkblue white-color weight-semibold">
                                <FormattedMessage id="register" />
                            </button>
                        </div>
                    </div>
                )}

                {contactSuccess && (
                    <Result
                        status="success"
                        title={<FormattedMessage id="contact-success" />}
                        subTitle={<FormattedMessage id="contact-success-info" />}
                        extra={[
                            <Button onClick={e => modalIsOpenHandler(false)} key="close"><FormattedMessage id="close" /></Button>,
                        ]}
                    />
                )}
            </section>

        </Modal>
    );
};

export default SignupWaitingListModal;
