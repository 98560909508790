import React, { useState, useEffect, } from "react";
import { Modal, Button, Row, Col, notification, } from 'antd';
import { LoadingOutlined, } from '@ant-design/icons';
import { FormattedMessage, useIntl, } from "react-intl";
import { DocumentDownload, } from '../../../../../services/documents';
import { DownloadSimple, X, } from "@phosphor-icons/react";

const DocumentDownloadModal = ({
    modalIsOpen,
    modalIsOpenHandler,
    documentData,
}) => {

    const [downloadPending, setDownloadPending] = useState(true);
    const [downloadURL, setDownloadURL] = useState(undefined);
    const intl = useIntl();

    useEffect(() => {
        setTimeout(() => {
            prepairDownload();
        }, 500);
        // eslint-disable-next-line
    }, []);

    const prepairDownload = () => {
        DocumentDownload(documentData).then(response => {
            if (response.status) {
                setDownloadPending(false);

                // download item from recived link
                var element = document.createElement('a');
                setDownloadURL(response.data.url);
                element.setAttribute('href', response.data.url);
                document.body.appendChild(element);
                element.click();

                // cleanup after a small delay
                setTimeout(() => {
                    document.body.removeChild(element);
                }, 1500);
            } else {
                notification['error']({
                    message: 'Document download',
                    description: intl.formatMessage({ id: response.msg }),
                });
            }
        })
    };

    return (

        <Modal
            open={modalIsOpen}
            title={<><DownloadSimple size={22} weight="light" className="mr-1" /> <FormattedMessage id="document-download" /></>}
            onCancel={() => modalIsOpenHandler(false)}
            width={750}
            footer={[
                <Button key="1" onClick={() => modalIsOpenHandler(false)}>
                    <span><X size={18} weight="light" className="mr-1" /> <FormattedMessage id="cancel" /></span>
                </Button >,
            ]}>

            <Row>
                <Col span={24}>
                    <h5><strong><FormattedMessage id={downloadPending ? "document-download-prepair" : "document-download-done"} /></strong></h5>
                </Col>
                <Col span={24}>
                    {downloadPending ? (
                        <div className="text-center" style={{ fontSize: "35px" }}>
                            <LoadingOutlined className="" />
                        </div>
                    ) : (
                        <div className="text-center p-4" >
                            <Button
                                size="large"
                                className="ant-btn-success"
                                href={downloadURL}
                                style={{ height: "60px", width: "250px", fontSize: "17px", lineHeight: "45px" }}
                            ><DownloadSimple size={18} weight="light" className="mr-1" /> <FormattedMessage id="document-download" /></Button>
                        </div>
                    )}
                </Col>
            </Row>


        </Modal>
    );
};


export default DocumentDownloadModal;
