import React, { useState, } from "react";
import { Modal, Button, Form, Switch, Card, notification, } from 'antd';
import { FormattedMessage, useIntl, } from "react-intl";
import { UserDelete, } from '../../../../../services/users';
import { Trash, X, } from "@phosphor-icons/react";


const DeleteUserModal = ({
    modalIsOpen,
    modalIsOpenHandler,
    userProfileData,
    history,
    refreshData,
}) => {

    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [confirmDelete, setConfirmDelete] = useState(false);
    const intl = useIntl();

    const onSubmit = () => {
        setButtonDisabled(true);
        setConfirmDelete(false);

        if (userProfileData) {
            // invoice exist, also delete from server
            UserDelete(userProfileData)
                .then(response => {
                    if (response.status) {
                        notification['success']({
                            message: 'User',
                            description: intl.formatMessage({ id: response.msg }),
                        });
                    } else {
                        notification['error']({
                            message: 'User',
                            description: intl.formatMessage({ id: response.msg }),
                        });
                    }
                    modalIsOpenHandler(false);
                    refreshData();
                })
                .catch(error => {
                    console.error('error: ', error);
                    setButtonDisabled(false);
                    if (error.status === 403) {
                        history('/dashboard');
                        notification['error']({
                            message: 'Account',
                            description: intl.formatMessage({ id: "ENDPOINT_ACCESS_DENIED" }),
                        });
                    }
                });

        } else {
            modalIsOpenHandler(false);
            history('/');
            notification['warning']({
                message: 'User',
                description: "User not found",
            });
        }

    };

    return (

        <Modal
            open={modalIsOpen}
            title={<><Trash size={18} weight="light" className="mr-1" /> <FormattedMessage id="delete-user" /></>}
            onCancel={() => modalIsOpenHandler(false)}
            width={750}
            className="user danger"
            footer={[
                <Button key="1" onClick={() => modalIsOpenHandler(false)}>
                    <span><X size={18} weight="light" className="mr-1" /> <FormattedMessage id="cancel" /></span>
                </Button>,
                <Button key="2"
                    danger={true}
                    type="primary"
                    loading={buttonDisabled}
                    disabled={!confirmDelete}
                    onClick={onSubmit}>
                    <span>{!buttonDisabled && (<Trash size={18} weight="light" className="mr-1" />)} <FormattedMessage id="delete-user" /></span>
                </Button>,
            ]}>

            <p><FormattedMessage id="delete-user-header" /></p>
            <Card sizee="small" title={<><strong><FormattedMessage id="delete-user-info" /> {userProfileData.user_data.user_first_name} {userProfileData.user_data.user_last_name}</strong>?</>}>
                <Form
                // labelCol={{ span: 20 }}
                // wrapperCol={{ span: 4 }}
                >

                    <Form.Item label={<FormattedMessage id="user-delete-confirm" />} valuePropName="checked">
                        <Switch
                            className="danger"
                            checked={confirmDelete}
                            onChange={e => setConfirmDelete(!confirmDelete)}
                        />
                    </Form.Item>

                </Form>
            </Card>

        </Modal>
    );
};


export default DeleteUserModal;
