import React from "react";
import { Menu, Image, notification, Badge, } from 'antd';
import { UserSignout } from '../../services/users';
import { useIntl, FormattedMessage, } from "react-intl";
import { CalendarCheck, Certificate, ChartLine, ListMagnifyingGlass, ShoppingCart, SignOut, SquaresFour, User } from "@phosphor-icons/react";

const Logo = require(`../../assets/images/sloep-logo.png`);

const DashboardMenu = ({
    counterShoppingcart = 0,
    history,
    auth,
    ...props
}) => {

    const { themeData: { branding_logo, branding_color_primary, permission_template, } = {} } = props;
    const { order_contract_manager, product_quotation, } = permission_template || {};
    const intl = useIntl();

    const signout = () => {
        const { username, access_token } = auth;

        UserSignout({ username: username, access_token: access_token }).then(response => {
            if (response.status) {
                history('/');
                notification['success']({
                    message: 'Uitloggen',
                    description: intl.formatMessage({ id: response.msg }),
                });
            }
        });
    };

    const menuItems = [
        {
            key: 0,
            // children,
            label: "Dashboard",
            type: "group",
            className: "fs-13 letter-spacing-087 mb-3 text-uppercase px-3",
            children: [
                {
                    key: 1,
                    icon: <SquaresFour size={20} weight="light" />,
                    label: "Dashboard",
                    className: "text-heading lh-1 text-capitalize active py-2",
                    onClick: () => { history("/dashboard") }
                },
                {
                    key: "cart",
                    icon: <ShoppingCart size={20} weight="light" />,
                    label: <Badge count={counterShoppingcart} style={{ left: "80px" }} color={branding_color_primary}>
                        <FormattedMessage id="shoppingcart" />
                    </Badge>,
                    className: "text-heading lh-1 text-capitalize active py-2",
                    onClick: () => { history("/registreren") }
                },
            ],
        },
        { type: 'divider' },
        {
            key: 2,
            // children,
            label: <FormattedMessage id="main-menu" />,
            type: "group",
            className: "fs-13 letter-spacing-087 mb-3 text-uppercase px-3",
            children: [
                {
                    key: "bookings",
                    icon: <CalendarCheck size={20} weight="light" />,
                    label: <FormattedMessage id="my-orders" />,
                    className: "text-heading lh-1 text-capitalize py-2",
                    onClick: () => { history("/orders") },
                },
                {
                    key: "invoices",
                    icon: <ChartLine size={20} weight="light" />,
                    label: <FormattedMessage id="my-invoice" />,
                    className: "text-heading lh-1 text-capitalize py-2",
                    onClick: () => { history("/finance") },
                },
                product_quotation &&
                {
                    key: "quotes",
                    icon: <ListMagnifyingGlass size={20} weight="light" />,
                    label: <FormattedMessage id="my-quotes" />,
                    className: "text-heading lh-1 text-capitalize py-2",
                    onClick: () => { history("/quotes") },
                },
                order_contract_manager &&
                {
                    key: "contracts",
                    icon: <Certificate size={20} weight="light" />,
                    label: <FormattedMessage id="my-contracts" />,
                    className: "text-heading lh-1 text-capitalize py-2",
                    onClick: () => { history("/contracts") },
                },
            ],
        },
        { type: 'divider' },
        {
            key: 7,
            label: <FormattedMessage id="profile-menu" />,
            type: "group",
            className: "fs-13 letter-spacing-087 mb-3 text-uppercase px-3",
            children: [
                {
                    key: 8,
                    icon: <User size={20} weight="light" />,
                    label: <FormattedMessage id="my-profile" />,
                    className: "text-heading lh-1 sidebar-item py-2 text-capitalize",
                    onClick: () => { history("/profile") },
                },
                {
                    key: 9,
                    icon: <SignOut size={20} weight="light" />,
                    label: <FormattedMessage id="logoff" />,
                    className: "text-heading lh-1 sidebar-item py-2 text-capitalize",
                    onClick: () => { signout() },
                },
            ],
        },

    ];

    return (

        <div className="">
            {/* Logo in top op menu */}
            <a href="/" className="display-flex dashboard-logo-header">
                <Image
                    className="p-2"
                    src={branding_logo ? `https://${process.env.REACT_APP_AWS_DATASTORE}/${branding_logo}` : Logo}
                    preview={false}
                />
            </a>

            <Menu
                className="mt-4"
                style={{ width: "100%" }}
                items={menuItems}
            />
        </div>
    );
};

export default DashboardMenu;