import React from "react";
import { Row, Col, Image, Layout, } from 'antd';
import Logo from './../../assets/images/sloep-logo.png';
import { XCircle, Question, } from "@phosphor-icons/react";
import { FormattedMessage } from "react-intl";

const { Content } = Layout;

const NotAuthorized = ({
  msg,
  ...props
}) => {


  return (

    <Content>
      <main className="">
        <div className="page-container-content-wrapper">
          <Row gutter={[16, 16]} align="middle" justify="space-around"
            style={{ height: "75vh", textAlign: "center" }}>

            <Col md={15} sm={20} xs={20} className="">
              <Image src={Logo} preview={false} styleee={{ maxWidth: "400px" }} />
            </Col>

            <Col span={20}>
              <Row
                align="middle"
                justify="space-around"
                className="text-not-authorized">
                <Col span={24} className="header">
                  <div className="column-container-wrapper">
                    <div
                      className="col-full display-flex flex-vertical-center gap-s note-card bg-secondary padding-s radius-m">
                      <XCircle size={20} className="white-color" />
                      <p className="content-description white-color phone-m-width-semifull">
                        {msg === "APPLICATION_NOT_LICENSED" ? (
                          <FormattedMessage id="APPLICATION_NOT_LICENSED" />
                        ) : (
                          <FormattedMessage id="APPLICATION_NOT_FOUND" />
                        )}
                      </p>
                    </div>
                  </div>

                </Col>

                <Col span={24} style={{ marginTop: 50 }}>
                  The application you are looking for does not exist or has been disabled.<br />

                  <div className="column-container-wrapper second-step__cta-nav" style={{ marginTop: 50 }}>
                    <div className="col-full display-flex flex-center-bottom">
                      <a href="https://smartrentalsolutions.nl">
                        <button type="button"
                          href="https://smartrentalsolutions.nl"
                          className="slv-button step__cta-nav-btn next-btn bg-secondary hover-bg-darkblue white-color weight-semibold margin-b-m next-nav-btn">
                          <Question size={18} className="icon" /> More info</button>
                      </a>
                    </div>
                  </div>

                </Col>

              </Row>
            </Col>

          </Row>
        </div>
      </main>
    </Content>

  );
};

export default NotAuthorized;