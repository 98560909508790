import React, { Suspense, lazy, } from 'react';
import { Routes, Route, BrowserRouter, } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";

import Spinner from '../components/Spinner';
import HomePage from '../cointainers/home';
import { ProfileDetailPage } from '../cointainers/dashboard/profile';

const NotFound = lazy(() => import('../components/Notfound'));
const PaymentPage = lazy(() => import('../cointainers/shoppingcart/cart.payment.page'));

const DashBoard = lazy(() => import('../cointainers/dashboard/dashboard.view'));
const BookingPage = lazy(() => import('../cointainers/dashboard/orders/'));
const BookingDetailPage = lazy(() => import('../cointainers/dashboard/orders/booking.details/booking.index'))
const FinancialPage = lazy(() => import('../cointainers/dashboard/invoice'));
const InvoiceDetailPage = lazy(() => import('../cointainers/dashboard/invoice/invoice.detail.index'));
const QuotesPage = lazy(() => import('../cointainers/dashboard/quotes/index'));
const QuoteDetailView = lazy(() => import('../cointainers/dashboard/quotes/quote.detail.view'));
const ContractsPage = lazy(() => import('../cointainers/dashboard/contracts/index'));
const ContractDetailView = lazy(() => import('../cointainers/dashboard/contracts/contract.detail.view'));

const Routers = ({ ...props }) => {
    const { language, setCurrentLanguage } = props;
    const additional_props = { language, setCurrentLanguage, ...props };

    return (
        // set on the meta tags you want to replace in index.html: data-rh="true"
        <HelmetProvider>
            <Suspense fallback={<Spinner />}>
                <BrowserRouter>
                    <Routes>
                        <Route path="/" exact element={<HomePage {...additional_props} {...props} />} />
                        <Route path="/arrangementen" exact element={<HomePage {...additional_props} {...props} />} />
                        <Route path="/registreren" exact element={<HomePage {...additional_props} {...props} />} />

                        <Route path="/login" exact element={<HomePage {...additional_props} {...props} />} />
                        <Route path="/login/:mail/:token" exact element={<HomePage {...additional_props} {...props} />} />
                        <Route path="/activation/:code" exact element={<HomePage {...additional_props} {...props} />} />
                        <Route path="/registration" exact element={<HomePage {...additional_props} {...props} />} />
                        <Route path="/onsite_registration" exact element={<HomePage {...additional_props} {...props} />} />
                        <Route path="/dashboard" element={<DashBoard {...additional_props} {...props} />} />
                        <Route path="/payment/:code" element={<PaymentPage {...additional_props} {...props} />} />
                        <Route path="/profile" element={<ProfileDetailPage {...additional_props} {...props} />} />
                        <Route path="/profile/:tab" element={<ProfileDetailPage {...additional_props} {...props} />} />

                        <Route path="/orders" element={<BookingPage {...additional_props} {...props} />} />
                        <Route path="/order/details/:booking_id" element={<BookingDetailPage {...additional_props} {...props} />} />
                        <Route path="/order/details/:booking_id/:tab" element={<BookingDetailPage {...additional_props} {...props} />} />

                        <Route path="/finance" element={<FinancialPage {...additional_props} {...props} />} />
                        <Route path="/finance/:tab" element={<FinancialPage {...additional_props} {...props} />} />
                        <Route path="/finance/details/:invoice_id" element={<InvoiceDetailPage {...additional_props} {...props} />} />

                        <Route path="/quotes" element={<QuotesPage {...additional_props} {...props} />} />
                        <Route path="/quote/details/:quote_id" element={<QuoteDetailView {...additional_props} {...props} />} />

                        <Route path="/contracts" element={<ContractsPage {...additional_props} {...props} />} />
                        <Route path="/contract/details/:booking_id" element={<ContractDetailView {...additional_props} {...props} />} />
                        <Route path="/contract/details/:booking_id/:bookdetail_id" element={<ContractDetailView {...additional_props} {...props} />} />

                        <Route path="/*" element={<NotFound />} />
                    </Routes>
                </BrowserRouter>

            </Suspense>
        </HelmetProvider>
    );
};

export default Routers;
