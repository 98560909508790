import React, { useState, } from "react";
// import { Link, } from 'react-router-dom';
import { FormattedMessage, } from "react-intl";
import HomeShoppingListView from './home.shoppinglist.view';

import CallIcon from '../../../assets/images/call.png';
import ChatIcon from '../../../assets/images/chat_bubble.png';

import SignupWaitingListModal from '../../../components/modals/signup.waitinglist.registration.modal';

const HomeRightView = ({
    history,
    formStep,
    shoppingListRef,
    handleSubmit,
    control,
    setValue,
    watch,
    reset,
    submitOrder,
    ...props
}) => {

    const [openWaitingListModal, setOpenWaitingListModal] = useState(false);
    const { themeData: { branding_phone } } = props;

    const openPhone = () => {
        window.location.href = `tel:${branding_phone}`;
    };

    const openWhatsApp = () => {
        const phone = branding_phone.replace(/\s/g, '');
        window.location.href = `https://api.whatsapp.com/send/?phone=${phone}&text&type=phone_number&app_absent=0`;
    };

    return (

        <div className="content-wrapper-right flex-35 tablet-width-semifull phone-l-width-full">
            <div className="inner-content-wrapper width-100">

                <HomeShoppingListView
                    history={history}
                    formStep={formStep}
                    shoppingListRef={shoppingListRef}
                    {...props} />

                {/* Button section */}
                {formStep === 1 && (
                    <section className="column-container bg-white radius-l padding-m margin-b-xl">
                        <div className="column-container-wrapper">
                            <div className="col-full">

                                <h2 className="content-title weight-bold size-h3 default-color">
                                    <FormattedMessage id="no-boats-available" />
                                </h2>

                                <p className="content-description margin-b-l">
                                    <FormattedMessage id="no-boats-available-description" />
                                </p>

                                <div className="reserve__container_cta display-flex gap-s">
                                    <button
                                        onClick={e => setOpenWaitingListModal(true)}
                                        className="slv-button transparent-btn bg-transparent flex-1 default-color weight-bold open-popup_btn">
                                        <FormattedMessage id="add-yourself-to-reserve-list" />
                                    </button>

                                    <button
                                        onClick={openPhone}
                                        className="slv-button transparent-btn bg-transparent flex-1 default-color weight-bold display-flex gap-s flex-center-center"><span
                                            className="btn-cta-icon display-flex flex-center-center"><img
                                                src={CallIcon} alt="" /></span>
                                        <FormattedMessage id="direct-call" />
                                    </button>

                                    <button
                                        onClick={openWhatsApp}
                                        className="slv-button transparent-btn bg-transparent flex-1 default-color weight-bold display-flex gap-s flex-center-center"><span
                                            className="btn-cta-icon display-flex flex-center-center"><img
                                                src={ChatIcon} alt="" /></span>
                                        <FormattedMessage id="app-ons" />
                                    </button>

                                </div>
                            </div>
                        </div>
                    </section>
                )}

                {/* Order buttons show only in the last step */}
                {formStep === 3 && (
                    <div className="column-container-wrapper hide show-flex-tablet">
                        <div className="col-full display-flex flex-between">
                            <button type="button"
                                onClick={e => history('/arrangementen')}
                                className="slv-button step__cta-nav-btn bg-secondary hover-bg-darkblue white-color weight-semibold me-1">
                                <FormattedMessage id="previous" />
                            </button>
                            <button
                                onClick={handleSubmit(submitOrder)}
                                type="button"
                                className="slv-button step__cta-nav-btn book-btn bg-secondary white-color weight-semibold ">
                                <FormattedMessage id="pay-and-reserve" />
                            </button>
                        </div>
                    </div>
                )}

            </div>


            {openWaitingListModal && (
                <SignupWaitingListModal
                    modalIsOpen={openWaitingListModal}
                    modalIsOpenHandler={setOpenWaitingListModal}
                    history={history}
                    {...props}
                />
            )}

        </div>
    );

};

export default HomeRightView;