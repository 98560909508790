import React, { useState, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { Tag } from 'antd';

const StatusBadge = ({
    status = "UNKNOWN",
    icon = undefined,
}) => {
    // primary, secondary, success, danger, warning, info, light, dark
    const [theme, setTheme] = useState();

    useEffect(() => {
        switch (status) {
            case "OPEN":
            case "DEACTIVATED":
                setTheme("geekblue");
                break;
            case "DRAFT":
            case "CONCEPT":
            case "PENDING":
            case "PENDING_PAYMENT":
            case "CREATING":
            case "RENTED_OUT":
                setTheme("gold");
                break;
            case "DELETED":
            case "ERROR_CREATING":
                setTheme("magenta");
                break;
            case "ACTIVE":
            case "ONLINE":
            case "CLOSED":
            case "PAID":
            case "APPROVED":
                setTheme("green");
                break;
            case "NUMBER_NOT_SET":
            case "PROCESSING":
            case "BESCHIKBAAR":
                setTheme("cyan");
                break;
            case "UNMANAGED":
                setTheme("default");
                break;
            default:
                setTheme("purple");
                break;
        }
        return () => {
            // cleanup
        }
    }, [status])

    return (
        <Tag color={theme}>
            {icon} <FormattedMessage id={status || "UNKNOWN"} />
        </Tag>
    );
};

export default StatusBadge;