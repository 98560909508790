import React, { useEffect, useState, } from "react";
// import { Link, } from 'react-router-dom';
import { notification, Row, Col, Button, Space, Form, Switch, Tooltip, } from 'antd';
import { FormattedMessage, } from "react-intl";
import { useForm, Controller, } from "react-hook-form";
import { CheckCircle, Cookie, Gear, Info, X, } from '@phosphor-icons/react';
import CookieInfoModal from './cookie.information.modal';
import DownloadsModal from '../modals/download.modal';

import SloepLogo from './../../assets/images/sloep-logo.png';
import FB from '../../assets/images/facebook.png';
import IG from '../../assets/images/instagram.png';
import LI from '../../assets/images/linkedin.png';

// import { CaretRight } from "@phosphor-icons/react";

const Footer = ({
    ...props
}) => {

    const [openDownloadModal, setOpenDownloadModal] = useState(false);
    const { branding_logo, branding_socials, branding_name,
        branding_phone, branding_location, branding_vat,
        branding_kvk, branding_email, templates, id,
    } = props;
    const [api, contextHolder] = notification.useNotification();
    const [cookieInfoOpen, setCookieInfoOpen] = useState(false);
    const { handleSubmit, control, } = useForm({ mode: "all" });

    // check cookie settings
    useEffect(() => {
        let isCookieBannerOpen = false;
        if ("cookie_consens_sloep" in localStorage) {
            if (localStorage.getItem("cookie_consens_sloep")) {
                isCookieBannerOpen = false;
            } else {
                isCookieBannerOpen = true;
            }
        } else {
            isCookieBannerOpen = true;
        }

        if (isCookieBannerOpen) {
            // openCookieBanner();
        }

        // eslint-disable-next-line
    }, []);

    const openCookieBanner = () => {
        api.open({
            message: <strong><FormattedMessage id="cookie-banner" /></strong>,
            icon: <Cookie size={24} />,
            description: <Row>
                <Col span={24}>
                    <FormattedMessage id="cookie-banner-info" />
                </Col>
            </Row>,
            className: 'cookie-banner',
            style: { width: 800, },
            placement: 'bottomLeft',
            duration: 0,
            key: 1,
            btn: (
                <Space>
                    <Button onClick={e => openCookieBannerSettings()}><Gear size={20} className="icon" /></Button>
                    <Tooltip title={<FormattedMessage id="cookie-additional-info" />}>
                        <Button onClick={e => setCookieInfoOpen(!cookieInfoOpen)}><Info size={20} /></Button>
                    </Tooltip>
                    <Button className="bg-secondary " onClick={e => cookieSet()}><CheckCircle size={20} className="mr-1 icon" /> Akkoord</Button>
                </Space>),
        });
    };

    const openCookieBannerSettings = () => {
        api.open({
            message: <strong><FormattedMessage id="cookie-banner" /></strong>,
            icon: <Cookie size={24} className="icon" />,
            description: <Row>
                <Col span={24}>
                    <FormattedMessage id="cookie-banner-info" />
                </Col>
                <Col span={24} className="mt-2">
                    <strong>
                        <FormattedMessage id="cookie-banner-settings-info" />
                    </strong>
                </Col>
                <Col span={24} className="mt-4">
                    <Form
                        labelCol={{ span: 8 }}
                        wrapperCol={{ span: 16 }}
                    // layout="vertical"
                    >

                        <Controller
                            control={control}
                            defaultValue={true}
                            name="allow_marketing"
                            render={({
                                field: { onChange, onBlur, value, name, ref },
                                fieldState: { error },
                            }) => (
                                <Form.Item
                                    labelAlign='left'
                                    label={<strong>Marketing & Advertisment</strong>}>
                                    <Switch
                                        onChange={onChange}
                                        checked={value}
                                        className="mr-1"
                                    /> <small><FormattedMessage id="cookie-marketing-info" /></small>
                                </Form.Item>
                            )}
                        />

                        <Controller
                            control={control}
                            defaultValue={true}
                            name="allow_traffic"
                            render={({
                                field: { onChange, onBlur, value, name, ref },
                                fieldState: { error },
                            }) => (
                                <Form.Item
                                    labelAlign='left'
                                    label={<strong>Traffic & Performance</strong>}>
                                    <Switch
                                        onChange={onChange}
                                        checked={value}
                                        className="mr-1"
                                    /> <small><FormattedMessage id="cookie-traffic-info" /></small>
                                </Form.Item>
                            )}
                        />
                    </Form>
                </Col>
            </Row>,
            className: 'cookie-banner',
            style: { width: 800, },
            placement: 'bottomLeft',
            duration: 0,
            key: 1,
            btn: (
                <Space>
                    <Button onClick={e => openCookieBanner()
                    }> <X size={20} className="icon" /></Button >
                    <Tooltip title={<FormattedMessage id="cookie-additional-info" />}>
                        <Button onClick={e => setCookieInfoOpen(!cookieInfoOpen)}><Info size={20} className="icon" /></Button>
                    </Tooltip>
                    <Button className="bg-secondary" onClick={handleSubmit(cookieSet)}><CheckCircle size={20} className="icon mr-2" /> <FormattedMessage id="save" /></Button>
                </Space>),
        });
    };

    const cookieSet = (data) => {
        localStorage.setItem('cookie_consens_sloep', true);
        if (data) {
            localStorage.setItem('cookie_settings_sloep', JSON.stringify(data));
        } else {
            localStorage.setItem('cookie_settings_sloep', JSON.stringify({ "allow_marketing": true, "allow_traffic": true }));
        }
        api.destroy();
        window.location.reload();
    };

    return (

        <footer className="padding-lr-s">

            {/* <section className="column-container footer__container">
                <div className="column-container-wrapper border-bottom-solid display-flex gap-s padding-tb-m">
                    <div className="flex-5p">
                        <img src={branding_logo ? `https://${process.env.REACT_APP_AWS_DATASTORE}/${branding_logo}` : SloepLogo} alt="Sloepverhuur logo" />
                    </div>
                    <div className="flex-70 display-flex flex-vertical-center">
                        <ul className="footer__container-menu-list list-style-none horizontal display-flex gap-s">
                            <li>Home</li>
                            <li><CaretRight size={18} weight="bold" className="primary-color icon" /></li>
                            <li className="weight-bold"><FormattedMessage id="reserve" /></li>
                        </ul>
                    </div>
                </div>
            </section> */}

            <section className="column-container footer__container display-flex flex-between padding-tb-xl border-bottom-solid">
                <div className="footer__container_column-item flex-5 phone-l-50 phone-m-width-full  flex-center-center">
                    <img className="logo-footer" src={branding_logo ? `https://${process.env.REACT_APP_AWS_DATASTORE}/${branding_logo}` : SloepLogo} alt="Sloepverhuur logo" />
                    {/* <h2 className="content-title size-h3 weight-semibold default-color"><FormattedMessage id="arrangements" /></h2>
                        <ul className="footer__container-menu-list list-style-none">
                            <li className="margin-b-s">Barbeque arrangement</li>
                            <li className="margin-b-s">Borrel arrangement</li>
                            <li className="margin-b-s">Lunch arrangement</li>
                        </ul> */}
                </div>
                <div className="footer__container_column-item flex-5 phone-l-50 phone-m-width-full">
                    <h2 className="content-title size-h3 weight-semibold default-color"><FormattedMessage id="company-details" /></h2>
                    <ul className="footer__container-menu-list list-style-none">
                        <li className="margin-b-s">{branding_name}</li>
                        <li className="margin-b-s">{branding_phone}</li>
                        <li className="margin-b-s">{branding_location?.location_note}</li>
                        <li className="margin-b-s">BTW: {branding_vat}</li>
                        <li className="margin-b-s">KvK: {branding_kvk}</li>
                    </ul>
                </div>
                <div className="footer__container_column-item flex-5 phone-l-50 phone-m-width-full">
                    <h2 className="content-title size-h3 weight-semibold default-color"><FormattedMessage id="about-us" /></h2>
                    <ul className="footer__container-menu-list list-style-none">
                        <li className="margin-b-s">{branding_email}</li>
                        <li className="margin-b-s">{branding_phone}</li>
                        <li className="margin-b-s"></li>
                        <li className="margin-b-s"></li>
                        <li className="margin-b-s"></li>
                    </ul>
                </div>
                <div className="footer__container_column-item flex-5 phone-l-50 phone-m-width-full">
                    <h2 className="content-title size-h3 weight-semibold default-color"><FormattedMessage id="information" /></h2>
                    <ul className="footer__container-menu-list list-style-none">
                        <li className="margin-b-s"><span className="pointer" onClick={openCookieBanner} aria-label={<FormattedMessage id="cookie-settings" />}><FormattedMessage id="cookie-settings" /></span></li>
                        <li className="margin-b-s"><span className="pointer" onClick={e => setCookieInfoOpen(!cookieInfoOpen)} aria-label={<FormattedMessage id="cookie-policy" />}><FormattedMessage id="cookie-policy" /></span></li>
                        <li className="margin-b-s"><span className="pointer" onClick={e => { setOpenDownloadModal(true) }}>Algemene voorwaarden</span></li>
                        <li className="margin-b-s"><span className="pointer" onClick={e => { setOpenDownloadModal(true) }}>Privacy statement</span></li>
                    </ul>
                </div>
            </section>


            <section className="column-container footer__container">
                <div className="column-container-wrapper display-flex flex-between padding-tb-m">
                    <div className="flex-3 phone-l-width-full">
                        <p className="content-description weight-regular default-color">Design door DLogic<br />© {new Date().getFullYear()}, Powered by Smart Rental Solutions</p>
                    </div>
                    <div className="flex-5 phone-l-width-full">
                        <ul className="list-style-none display-flex gap-xs flex-center-center footer-icons">

                            {branding_socials && (
                                <>
                                    {branding_socials.instagram_enable &&
                                        <li><a href={branding_socials.instagram_link} target="_blank" rel="noreferrer noopener"><img src={IG} alt="Instagram logo" /></a></li>}

                                    {branding_socials.facebook_enable &&
                                        <li><a href={branding_socials.facebook_link} target="_blank" rel="noreferrer noopener"><img src={FB} alt="Facebook logo" /></a></li>}

                                    {branding_socials.linkedin_enable &&
                                        <li><a href={branding_socials.linkedin_link} target="_blank" rel="noreferrer noopener"><img src={LI} alt="LinkedIN logo" /></a></li>}
                                </>
                            )}
                        </ul>
                    </div>
                </div>
            </section>

            {/* Cookie banner placeholder */}
            {contextHolder}

            {cookieInfoOpen && (
                <CookieInfoModal
                    modalIsOpen={cookieInfoOpen}
                    modalIsOpenHandler={setCookieInfoOpen}
                />
            )}

            {openDownloadModal && (
                <DownloadsModal
                    modalIsOpen={openDownloadModal}
                    modalIsOpenHandler={setOpenDownloadModal}
                    templates={templates}
                    id={id}
                />
            )}

        </footer >
    );
};

export default Footer;