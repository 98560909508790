export const fallbackLanguage = "en";               // default fallback language
export const languages = ["en", "nl", "de"];        // list with supported languages

// Check if we have a support langauge, if not return the fallback
export const validateLanguage = (lang) => {
    return languages.includes(lang) ? lang : fallbackLanguage;
};

export const detectBrowserlanguage = (defaultLang) => {
    if (typeof navigator === 'object') {
        let navLang = navigator.language || navigator.userLanguage || defaultLang || fallbackLanguage;

        // check if localstore exist containing the user_language
        const localdata = JSON.parse(localStorage.getItem('authentication_sloep'), '{}');
        if (localdata?.user_language) {
            return validateLanguage(localdata.user_language.toLowerCase());
        }

        return validateLanguage(navLang.substring(0, 2));
    };
};