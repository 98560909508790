import React, { useEffect, useState } from 'react';
import { IntlProvider, FormattedMessage } from 'react-intl';
import { ConfigProvider, Col, Button, Row, Space, notification, } from 'antd';

import { detectBrowserlanguage } from './services/language';
import Routers from './routes';
import { GApageView, GAevent, GAPurchaseEvent, } from './config';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import messages_nl from "./translations/nl.json";
import messages_en from "./translations/en.json";
import messages_de from "./translations/de.json";
import { ArrowsClockwise, } from '@phosphor-icons/react';
import 'dayjs/locale/nl';

const App = () => {

    const [LanguageObject, setLanguageObject] = useState({});
    const [currentLanguage, setCurrentLanguage] = useState(detectBrowserlanguage('en') || 'en');
    const [loading, setLoading] = useState(true);
    const [counterShoppingcart, setCounterShoppingcart] = useState(0);
    const [shoppingCart, setShoppingCart] = useState([]);
    const [newVersion, setNewVersion] = useState(false);
    const [api, contextHolder] = notification.useNotification();
    const [themeData, setThemeData] = useState({
        border_radius: 3,
        // branding_color_primary: '#46005c',
        // branding_color_primary_hover: '#7d129e',
    });

    useEffect(() => {
        setLanguageObject({
            messages: { en: messages_en, nl: messages_nl, de: messages_de },
        }, setLoading(false));
    }, []);

    useEffect(() => {
        const shoppingcart = localStorage.getItem('shoppingcart_sloep');
        const cart = JSON.parse(shoppingcart);
        if (cart) {
            setShoppingCart(cart);
            setCounterShoppingcart(cart.length);
        }
    }, []);

    useEffect(() => {
        // openNotification();

        // Set timeout to make sure view has renderd.
        setTimeout(() => {
            if (newVersion) {
                openNotification();
            }
        }, 1500);

        // eslint-disable-next-line
    }, [newVersion]);

    const triggerShoppingCart = (data) => {
        const shoppingcart = localStorage.getItem('shoppingcart_sloep');
        const cart = JSON.parse(shoppingcart) || [];
        // const uuid = crypto.randomUUID();

        if (data) {
            localStorage.setItem('shoppingcart_sloep', JSON.stringify(data));
            setCounterShoppingcart(data.length);
            setShoppingCart(data);
        } else {
            setShoppingCart(cart);
            setCounterShoppingcart(cart.length);
        }
    };

    const openNotification = () => {
        api.open({
            message: <strong><FormattedMessage id="new-version" /></strong>,
            type: 'info',
            description: <Row>
                <Col span={24}>
                    <FormattedMessage id="new-version-info" />
                </Col>
            </Row>,
            className: 'custom-class',
            style: { width: 600, },
            placement: 'bottomLeft',
            duration: 0,
            key: 1,
            btn: (
                <Space>
                    <Button size="small" onClick={e => window.location.reload()}><ArrowsClockwise size={16} className="icon mr-1" /> Refresh</Button>
                </Space>),
        });
    };

    // Changes made here
    serviceWorkerRegistration.register({
        onUpdate: registration => {
            const waitingServiceWorker = registration.waiting;

            if (waitingServiceWorker) {
                waitingServiceWorker.addEventListener("statechange", event => {
                    if (event.target.state === "activated") {
                        setNewVersion(true);
                        // console.log('new version detected!');
                        // if (
                        //     window.confirm(
                        //         "There is a new version of the app ready. Please reload to update."
                        //     )
                        // ) {
                        //     window.location.reload()
                        // }
                    }
                })
                waitingServiceWorker.postMessage({ type: "SKIP_WAITING" })
            }
        },
    })

    return (
        <React.Fragment>
            {loading ? (<p>Loading settings...</p>) : (
                <ConfigProvider
                    csp={{ nonce: `${process.env.REACT_APP_GENERATED_NONCE_VALUE}` }}
                    theme={{
                        token: {
                            fontFamily: "'sweetsanspro 400'",
                            fontSize: 16,
                            colorPrimary: themeData.branding_color_primary || '#FF5A3F',
                            colorPrimaryHover: themeData.branding_color_hover || themeData.branding_color_primary || '#006B83',
                            // borderRadius: themeData.border_radius || 4,
                        },
                        hashed: false,
                    }}>
                    <IntlProvider locale={currentLanguage} messages={LanguageObject.messages[currentLanguage]}>
                        <Routers language={currentLanguage}
                            setCurrentLanguage={setCurrentLanguage}
                            themeData={themeData}
                            setThemeData={setThemeData}
                            counterShoppingcart={counterShoppingcart}
                            triggerShoppingCart={triggerShoppingCart}
                            shoppingCart={shoppingCart}
                            GApageView={GApageView}
                            GAevent={GAevent}
                            GAPurchaseEvent={GAPurchaseEvent}
                        />
                        {contextHolder}
                    </IntlProvider>
                </ConfigProvider>
            )}
        </React.Fragment>
    );
}

export default App;