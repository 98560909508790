import React, { useEffect, useState } from "react";
import ReactGA from "react-ga4";
import TagManager from 'react-gtm-module';

import Spinner from './../Spinner';
import { CustomCSS } from "../helpers";
import { ApplicationStatus } from '../../services/public';
import NotAuthorized from "../not.authorized";
import ErrorPage from '../error';

import { useFetchAuth } from '../../services/authentication/auth';

const PublicLayout = ({
    children,
    className = "",
    ...props
}) => {

    const { setThemeData, GApageView, } = props;
    const [loadingData, setLoadingData] = useState(true);
    const [authorized, setAuthorized] = useState(false);
    const [licenseData, setLicenseData] = useState(false);
    const [connectError, setConnectError] = useState(false);
    const [appData, setAppData] = useState({});
    const { auth } = useFetchAuth({ required: false });
    GApageView(window.location.pathname);

    const cookie_settings = JSON.parse(localStorage.getItem("cookie_settings_sloep")) || { "allow_marketing": true, "allow_traffic": true };
    const cookie_consens = localStorage.getItem("cookie_consens_sloep") || true;


    useEffect(() => {
        getApplicationStatus();
        // eslint-disable-next-line
    }, []);

    // activate google analytics
    useEffect(() => {
        if (appData.plugin_data) {
            // init GA
            for (let index = 0; index < appData.plugin_data.length; index++) {
                const element = appData.plugin_data[index];
                if (element.plugin_key === "google_analytics") {
                    ReactGA.initialize(element.ga_property_id);
                }
            };

            // init GTM
            // TODO: implement GTM

            if (cookie_consens) {
                for (let index = 0; index < appData.plugin_data.length; index++) {
                    const element = appData.plugin_data[index];

                    if (cookie_settings.allow_traffic) {
                        if (element.plugin_key === "google_analytics") {
                            ReactGA.initialize(element.ga_property_id);
                            console.log('google_analytics loaded: ', element.ga_property_id);
                        }
                        // Hotjar.init(HOTJAR_SITE_ID, HOTJAR_VERSION);
                    }

                    if (cookie_settings.allow_marketing) {
                        if (element.plugin_key === "google_tag_manager") {
                            TagManager.initialize({ gtmId: element.gtm_id });
                            console.log('google_tag_manager loaded: ', element.gtm_id);
                        }
                    }
                };
            }
            else { console.log('Trackers NOT loaded...'); }

        }

        // eslint-disable-next-line
    }, [appData]);

    const getApplicationStatus = () => {
        ApplicationStatus()
            .then(response => {
                setAppData(response.data, setLoadingData(false));
                setThemeData(response.data);
                setAuthorized(response.status);
                setLicenseData(response.msg);
                setConnectError(false);
            })
            .catch(error => {
                console.error('error: ', error);
                if (error?.status !== 403) {
                    setConnectError(true);
                    setLoadingData(false);
                }
            })
    };

    if (loadingData) {
        return (<Spinner />);
    } else {
        return (
            <div className="">
                <CustomCSS {...props} />
                {authorized && !connectError ? (
                    <React.Fragment>
                        {/* {children} */}
                        {React.Children.map(children, (child) => {
                            return React.cloneElement(child, { ...appData, auth: auth });
                        })}
                    </React.Fragment>
                ) : (
                    <>
                        {connectError ? (
                            <ErrorPage refresh={getApplicationStatus}{...props} />
                        ) : (
                            <NotAuthorized msg={licenseData} {...props} />
                        )}
                    </>
                )}
            </div>
        );
    }
};

export default PublicLayout;