import React from "react";
import { Modal, Result, List, Image, Button, } from 'antd';

import { FormattedMessage, } from "react-intl";
import { X } from "@phosphor-icons/react";
import Moment from "react-moment";

import PlaceHolder from '../../../assets/images/placeholder.png';

const CartNotAvailableModal = ({
    modalIsOpen,
    modalIsOpenHandler,
    notAvailableData,
    ...props
}) => {

    const { language } = props;
    const renderHTMLContent = (htmlContent) =>
        React.createElement('div', {
            dangerouslySetInnerHTML: { __html: htmlContent },
        });

    return (
        <Modal
            open={modalIsOpen}
            closable={false}
            maskClosable={false}
            width={650}
            footer={[
                <Button onClick={e => modalIsOpenHandler(false)} key="close"><X size={16} className="mr-1 icon" /><FormattedMessage id="close" /></Button>,
            ]}
            onCancel={() => modalIsOpenHandler(false)}>

            <Result
                className="result-background"
                status="error"
                title={<FormattedMessage id="cart-order-error" />}
                subTitle={<FormattedMessage id="cart-order-error-period" />}
            />

            <List
                itemLayout="horizontal"
                // className="mt-4"
                dataSource={notAvailableData}
                // rowKey={item => item?.option_id}
                renderItem={(item, index) => (
                    <List.Item
                        actions={[
                            // <span key="list-loadmore-edit"> per dag</span>,
                            // <Switch key="list-loadmore-more">more</Switch>
                        ]}>
                        <List.Item.Meta
                            avatar={<Image
                                preview={false}
                                style={{ objectFit: "cover", width: "150px" }}
                                src={item.product_data.product_images[0]?.image_s3_key ? `https://${process.env.REACT_APP_AWS_DATASTORE}/${item.product_data.product_images[0]?.image_s3_key}` : PlaceHolder} />}
                            title={<span>{item.product_data?.product_names?.[`product_name_${language}`] || item.product_data?.product_name__nl}: <Moment date={item.date_range[0]} format="DD-MM-yyyy" /> om {item.start_time}</span>}
                            description={renderHTMLContent(item.product_data?.product_descriptions?.[`product_description_${language}`] || item.product_data?.product_descriptions?.product_description_nl || item.product_data?.product_description)}
                        />
                    </List.Item>
                )}
            />

        </Modal>
    );
};

export default CartNotAvailableModal;
