import React, { useEffect, useState, useRef, } from "react";
import { useNavigate, useLocation, useParams, } from 'react-router-dom';
import { useIntl, } from "react-intl";
import { useLocalStorage } from 'usehooks-ts';
import { notification, } from 'antd';
import { QuestRegister, AccountActivationStatus } from '../../services/register';
import { useForm, } from "react-hook-form";

import HeaderBar from '../../components/header/header';
import HomeLeftView from './views/home.left.view';
import HomeRightView from './views/home.right.view';
import Footer from '../../components/footer';
import PublicLayout from '../../components/layouts/public.layout';

import ValidationPendingModal from '../../components/account.modals/views/validation.pending.modal';
import AccountLoginModal from '../../components/account.modals/account.login.modal';

const HomePage = ({

    ...props
}) => {

    const [formStep, setFormStep] = useState(1);
    const [openPendingModal, setOpenPendingModal] = useState(false);
    const [customerID, setCustomerID] = useState(undefined);
    const [openValidationModal, setOpenValidationModal] = useState(false);
    const [openLoginModal, setOpenLoginModal] = useState(false);
    const [, setAuth] = useState(undefined);        // authentication info will retrive from headerbar
    const [defaultLoginForm, setDefaultLoginForm] = useState("LOGIN");
    const [userEmail, setUserEmail] = useState();

    const history = useNavigate();
    const { pathname } = useLocation();
    const { code } = useParams();
    const { counterShoppingcart, language, } = props;
    const [shoppingCart] = useLocalStorage('shoppingcart_sloep', []);
    const { handleSubmit, control, setValue, watch, reset, } = useForm({ mode: 'onChange', });
    const shoppingListRef = useRef(null);
    const intl = useIntl();

    useEffect(() => {

        if (pathname.indexOf('activation') > 0) {
            if (code) { getValidationStatus(code); }
        }

        if (pathname.indexOf('login') > 0) {
            setOpenLoginModal(true);
        }

        if (pathname.indexOf('registration') > 0) {
            setDefaultLoginForm("SIGN_UP");
            setOpenLoginModal(true);
        }

        if (pathname.indexOf('onsite_registration') > 0) {
            setDefaultLoginForm("SIGN_UP_ONSITE");
            setOpenLoginModal(true);
        }

        // eslint-disable-next-line
    }, [pathname, code]);

    // check for the page navigation
    useEffect(() => {
        if (shoppingCart.length === 0) {
            history('/');
            setFormStep(1);
        } else {
            switch (pathname) {
                case '/':
                    setFormStep(1);
                    break;
                case '/arrangementen':
                    setFormStep(2);
                    break;
                case '/registreren':
                    setFormStep(3);
                    break;
                default:
                    break;
            };
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname, counterShoppingcart, shoppingCart]);

    const submitOrder = (data) => {
        setOpenPendingModal(true);
        createQuestAccount(data);
    };

    const createQuestAccount = (data) => {
        const post_data = {
            customer_type: "CONSUMER",
            agree_privacy_statement: false,
            agree_terms: false,
            agree_mailling_list: false,
            customer_name: `${data.user_first_name} ${data.user_last_name}`,
            email: data.user_email,
            first_name: data.user_first_name,
            last_name: data.user_last_name,
            language: language,
            phone_number: data.phone_number,
            booking_note: data.booking_note,
            ...data
        };

        QuestRegister(post_data).then(response => {
            if (response.status) {
                // notification['success']({
                //     message: 'Sign-up',
                //     description: intl.formatMessage({ id: response.msg }),
                // });
                setCustomerID(response.data.customer_id);
            } else {
                notification['error']({
                    message: 'Sign-up',
                    description: intl.formatMessage({ id: response.msg }),
                });
            }
        })
            .catch(error => {
                console.error('UserLogin error: ', error);

                notification['error']({
                    message: 'Inloggen',
                    description: "Oeps something is wrong: Cannot connect to authentication server!",
                });
            });
    };

    const getValidationStatus = (code) => {
        // get the validation status
        AccountActivationStatus(code)
            .then(response => {
                if (response.status) {
                    setUserEmail(response.data.user_email);
                    setOpenValidationModal(true);
                } else {
                    notification['error']({
                        message: 'Account activation',
                        description: intl.formatMessage({ id: response.msg }),
                    });
                }
            })
            .catch(error => {
                console.error('error: ', error);
                notification['error']({
                    message: 'Account activation',
                    description: intl.formatMessage({ id: "ACCOUNT_ACTIVATION_NOT_POSSIBLE" }),
                });
            });
    };

    return (
        <PublicLayout {...props}>
            <HeaderBar {...props} />

            <main className="page-container-content">
                <div className="page-container-content-wrapper display-flex flex-between padding-s">

                    <HomeLeftView
                        history={history}
                        formStep={formStep}
                        shoppingListRef={shoppingListRef}
                        submitOrder={submitOrder}
                        customerID={customerID}
                        setOpenPendingModal={setOpenPendingModal}
                        openPendingModal={openPendingModal}
                        handleSubmit={handleSubmit}
                        control={control}
                        setValue={setValue}
                        watch={watch}
                        reset={reset}
                        {...props} />

                    <HomeRightView
                        history={history}
                        formStep={formStep}
                        shoppingListRef={shoppingListRef}
                        handleSubmit={handleSubmit}
                        control={control}
                        setValue={setValue}
                        watch={watch}
                        reset={reset}
                        submitOrder={submitOrder}
                        {...props} />
                </div>

                {openLoginModal && (
                    <AccountLoginModal
                        history={history}
                        setAuth={setAuth}
                        modalIsOpen={openLoginModal}
                        modalIsOpenHandler={setOpenLoginModal}
                        defaultForm={defaultLoginForm}
                        {...props}
                    />
                )}

                <ValidationPendingModal
                    modalIsOpen={openValidationModal}
                    modalIsOpenHandler={setOpenValidationModal}
                    userEmail={userEmail}
                    validationToken={code}
                    // loading={loading}
                    history={history}
                />

            </main>

            <Footer {...props} />
        </PublicLayout>
    )
};

export default HomePage;