import React from "react";
import { Modal, Result, } from 'antd';
import { FormattedMessage, } from "react-intl";
import { LoadingOutlined, } from '@ant-design/icons';

const CartPendingModal = ({
    modalIsOpen,
    modalIsOpenHandler,
    loading,
}) => {

    return (
        <Modal
            open={modalIsOpen}
            closable={false}
            maskClosable={false}
            width={650}
            footer={[]}
            onCancel={() => modalIsOpenHandler(false)}>

            {loading && (
                <>
                    <div className="text-center">
                        <h4><FormattedMessage id="order-shoppingcart-pending" /></h4>
                    </div>
                    <div className="spinner-container">
                        <LoadingOutlined />
                    </div>
                </>
            )}

            {!loading && (
                <Result
                    className="result-background"
                    status="success"
                    title={<FormattedMessage id="cart-order-success" />}
                    subTitle={<FormattedMessage id="cart-order-success-info" />}
                />

            )}

        </Modal>
    );
};

export default CartPendingModal;
