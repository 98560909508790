import dayjs from 'dayjs';

export const getRentalPeriod = (date_range, time_slots) => {
    // this function retuns the correct timeslot for the given date_range

    if (!(Array.isArray(date_range) && date_range.length === 2 && date_range.every(item => item instanceof Date))) {
        console.error('getRentalPeriod: date_range must a list, contains two dates: [new Date(),new Date()].');
        // throw new Error("date_range in getRentalPeriod is not an instance of Date.");
        return {};
    }

    // Function to convert day-month string to Date object
    const convertToDate = (dateString) => {
        const [day, month] = dateString.split("-");
        const year = new Date().getFullYear();
        return new Date(`${year}-${month}-${day}`);
    };

    // Sort the timeslots by the period length
    const timeslots = time_slots?.filter(slot => slot.ts_enabled)?.sort((a, b) => {
        const [startPeriodA, endPeriodA] = a.ts_period_range.split("::").map(convertToDate);
        const [startPeriodB, endPeriodB] = b.ts_period_range.split("::").map(convertToDate);
        const periodLengthA = Math.abs(endPeriodA - startPeriodA);
        const periodLengthB = Math.abs(endPeriodB - startPeriodB);

        return periodLengthA - periodLengthB;
    });


    const fitsInRange = timeslots?.find(item => {
        // ts_period_range has been formated: "01-01::31-12"
        const [startPeriod, endPeriod] = item.ts_period_range.split("::").map(convertToDate);
        // const startRange = new Date(convertToDate(date_range[0]));
        // const endRange = new Date(convertToDate(date_range[1]));
        const startRange = date_range[0];
        const endRange = date_range[1];

        if ((startRange >= startPeriod) && (endRange <= endPeriod)) {
            return item;
        }
        return false;
    });


    // const timeSlotRentalPeriod = timeslots?.find(item => {
    //     const [startPeriod, endPeriod] = item.ts_period_range.split("::").map(convertToDate);
    //     const startRange = new Date(convertToDate(date_range));

    //     if ((startRange >= startPeriod) && (startRange <= endPeriod)) {
    //         return item;
    //     }
    //     return false;
    // });

    // if (typeof date_range === 'string') {
    //     // timeslot per hour is probably used, because only one date is given
    //     const tmp = timeSlotRentalPeriod;
    //     console.log('tmp getRentalPeriod: ', tmp);
    //     return tmp;
    // } else {

    const tmp = fitsInRange;
    return tmp;
    // }

};

export const getLowestPrice = (data, possibleOptions) => {
    const product_pricing = data.product_pricing;
    let mandatory_prices = [];

    // check if we have mandatory options, add this prices to the total
    for (let index = 0; index < possibleOptions.length; index++) {
        const element = possibleOptions[index];
        if (element.product_pricing.option_mandatory) {
            mandatory_prices = element.product_pricing.product_time_slots.map(item => item.ts_interval_pricing.map(item_a => item_a.interval_price)).flat();
        }
    }

    // return if product is advanced
    // TODO: Add the mandatory options
    if (data.product_type === "PRODUCT_ADVANCED") {
        switch (data.product_subscription_data.subscription_interval_type) {
            case "DAY":
                return data.product_subscription_data.product_price / data.product_subscription_data.subscription_interval;
            case "MONTH":
                return data.product_subscription_data.product_price / (data.product_subscription_data.subscription_interval / 30);
            default:
                return data.product_subscription_data.product_price;
        };
    }

    const prices = product_pricing?.product_time_slots?.map(item => item.ts_interval_pricing).flat();
    const lowestPrice = prices.reduce((lowest, item) => {
        return item.interval_price < lowest.interval_price ? item : lowest;
    }, prices[0]);

    if (mandatory_prices.length === 0) {
        return lowestPrice.interval_price;
    } else {
        return lowestPrice.interval_price + mandatory_prices.reduce((min, price) => Math.min(min, price), mandatory_prices[0]);
    }

};

export const calculateDeposit = (record) => {

    // If webshop item, no deposit is needed.
    if (record.product_data.product_type === "SHOP") { return 0; }

    // if product_type is PRODUCT_ADVANCED, return the deposit from the product_subscription_deposit_enable
    if (record.product_data.product_type === "PRODUCT_ADVANCED") {
        return record.product_data.product_subscription_data.product_subscription_deposit_enable ? record.product_data.product_subscription_data.product_subscription_deposit : 0;
    }

    let deposit_total_price = 0;
    let selectedOptions = record.selected_options;

    deposit_total_price = (record.product_data.product_pricing.product_deposit_enable ? record.product_data.product_pricing.product_deposit * record.bookdetail_amount : 0);

    // calculate the selected options
    for (let index = 0; index < selectedOptions?.length; index++) {
        const element = selectedOptions[index];

        if (element?.enable) {
            // getting the deposit / borg price
            const option_details = record.possible_options?.find((item) => element.product_id === item.product_id);
            deposit_total_price = deposit_total_price + (option_details?.product_pricing?.product_deposit_enable ? (option_details.product_pricing.product_deposit * (element.bookdetail_amount || 1)) : 0);
        }
    };

    return deposit_total_price;
};

export const calculateTotal = (record, vat_rates, coupon_code) => {

    const check_properties = record.hasOwnProperty('product_data') &&
        record.hasOwnProperty('days_free_of_charge') &&
        // record.hasOwnProperty('selected_options') &&
        record.hasOwnProperty('minimal_24hours_order') &&
        record.hasOwnProperty('bookdetail_amount');
    if (!check_properties) {
        console.error('Debug info in calculateTotal: ', record);
        throw new Error("record in calculateTotal has not all the required properties.");
    }

    // return total_price, price_per_interval, total_interval
    // from the item record
    if (vat_rates === undefined) { vat_rates = []; };
    if (!record.hasOwnProperty('selected_options')) { record.selected_options = []; }
    var price_found = false;
    var total_price = 0;
    var total_discount = 0;
    var price_per_interval = 0;
    var total_vat = {};
    var total_interval = 0;

    // If date_range is not set and product_type not PRODUCT_ADVANCED or SHOP, return 0
    if (record.product_data.product_type === "PRODUCT_ADVANCED") {
        // console.log('PRODUCT_ADVANCED skip');
    }
    else if (record.product_data.product_type === "SHOP") {
        // console.log('SHOP skip');
    }
    else {
        if (record.date_range?.length !== 2) {
            return {
                total_price: total_price,
                price_per_interval: price_per_interval,
                total_interval: 0,
                total_vat: total_vat,
                total_discount: total_discount,
            };
        }
    }

    var days_free_of_charge = record.days_free_of_charge;
    var selectedOptions = record.selected_options || [];
    var product_time_slots = {};
    if (record.ts_id) {
        product_time_slots = record.product_data.product_pricing.product_time_slots.find(item => item.ts_id === record.ts_id);
    } else {
        product_time_slots = getRentalPeriod(record.date_range, record.product_data.product_pricing.product_time_slots);
    }

    var interval_type = product_time_slots?.ts_interval_type;

    switch (interval_type) {
        case "DAY":
            total_interval = calculateInterval(record.date_range, days_free_of_charge, interval_type);
            break;
        case "HOUR":
            total_interval = record.interval_amount || 1;
            break;
        default:
            console.warn("No interval type found, default to 1");
            total_interval = 1;
            break;
    };

    var product_vat_group = record.product_data.product_pricing.product_vat_group;
    var product_pricing = product_time_slots?.ts_interval_pricing;
    var minimal_24hours_order = record.minimal_24hours_order;

    // check we have the 24 minimum only if the product_type is correct
    if (record.product_data.product_type !== "PRODUCT_ADVANCED" && record.product_data.product_type !== "SHOP") {
        if (product_time_slots === undefined) {
            // No period / timeslot found, return 0
            return {
                total_price: total_price,
                price_per_interval: price_per_interval,
                total_interval: total_interval,
                total_vat: total_vat,
                total_discount: total_discount,
            };
        }

        product_pricing?.sort((a, b) => a.interval_amount - b.interval_amount);
        if (minimal_24hours_order && total_interval > 0) {
            total_interval = total_interval - 1;
        }
    }

    if (record.product_data.product_type === "PRODUCT_ADVANCED") {
        const tmp_result = calculateTotalProductAdvanced(record, vat_rates, total_vat, coupon_code);
        total_price = total_price + tmp_result.total_price;
        price_per_interval = tmp_result.total_price;
        total_vat = tmp_result.total_vat;
        total_discount += tmp_result.total_discount;
        price_found = true;
    }

    if (record.product_data.product_type === "SHOP") {
        const tmp_result = calculateTotalWebshop(record, vat_rates, total_vat, coupon_code);
        total_price = total_price + (tmp_result.total_price * record.bookdetail_amount);
        price_per_interval = tmp_result.total_price;
        total_vat = tmp_result.total_vat;
        total_discount += tmp_result.total_discount;
        price_found = true;
    }

    let tmp_price = 0;
    let discount = 0;
    // Check for a direct match in product_pricing
    if (!price_found) {
        for (let index = 0; index < product_pricing?.length; index++) {
            const element = product_pricing[index];

            if (total_interval === element.interval_amount) {
                price_per_interval = element.interval_price;
                price_found = true;
                tmp_price = (record.bookdetail_amount * (total_interval * price_per_interval));
                discount = calculateDiscountPercentage(tmp_price, coupon_code);
                total_discount += discount;
                total_price = total_price + tmp_price;
                total_vat = getVatPercentage(vat_rates, product_vat_group, total_vat, tmp_price - discount);
                break;
            }
        };
    }

    // not a direct match found in previouse loop, look for between values.
    if (!price_found) {
        for (let index = 0; index < product_pricing?.length; index++) {
            const element = product_pricing[index];
            if (total_interval >= element.interval_amount && total_interval < product_pricing[index + 1 < product_pricing.length ? index + 1 : index].interval_amount) {
                price_per_interval = element.interval_price;
                price_found = true;
                tmp_price = (record.bookdetail_amount * (total_interval * price_per_interval));
                discount = calculateDiscountPercentage(tmp_price, coupon_code);
                total_discount += discount;
                total_price = total_price + tmp_price;
                total_vat = getVatPercentage(vat_rates, product_vat_group, total_vat, tmp_price - discount);
                break;
            }
        };
    }

    // Price not found probably more day's then the highest option
    if (!price_found) {
        var largest = product_pricing[0].interval_amount;
        var largest_index = 0;

        for (let index = 0; index < product_pricing.length; index++) {
            const element = product_pricing[index];
            if (largest < element.interval_amount) {
                largest = element.interval_amount;
                largest_index = index;
            }
        };

        price_per_interval = product_pricing[largest_index].interval_price;
        tmp_price = (record.bookdetail_amount * (total_interval * price_per_interval));
        discount = calculateDiscountPercentage(tmp_price, coupon_code);
        total_discount += discount;
        total_price = total_price + tmp_price;
        total_vat = getVatPercentage(vat_rates, product_vat_group, total_vat, tmp_price - discount);
    }

    // calculate the selected options
    selectedOptions?.forEach(element => {
        let tmp_price = 0;
        if (element?.enable) {
            if (element.product_one_time_item) {
                tmp_price = (element.bookdetail_amount || record.bookdetail_amount) * element.price; //option_data.product_pricing.product_pricing[0].price;
                discount = calculateDiscountPercentage(tmp_price, coupon_code);
                total_discount += discount;
            } else {
                if (record.parent_product_data?.product_type === "PRODUCT_ADVANCED") {
                    tmp_price = ((element.bookdetail_amount || record.bookdetail_amount) * element.price);
                    discount = calculateDiscountPercentage(tmp_price, coupon_code);
                    total_discount += discount;
                } else {
                    tmp_price = ((element.bookdetail_amount || record.bookdetail_amount) * (total_interval * element.price));
                    discount = calculateDiscountPercentage(tmp_price, coupon_code);
                    total_discount += discount;
                }
            }
        }
        total_vat = getVatPercentage(vat_rates, element.product_vat_group, total_vat, tmp_price - discount);
        total_price = total_price + tmp_price;
    });

    return {
        total_price: total_price,
        price_per_interval: price_per_interval,
        total_interval: total_interval,
        total_vat: total_vat,
        total_discount: total_discount,
    };
};

export const calculateDiscountPercentage = (total_price, coupon_code) => {
    // This function will calculate the discount based on the coupon code (percentage only).
    // The fixed amount is calculated in the shoppingcart itself.
    if (total_price === undefined) { return 0; }
    if (coupon_code === undefined) { return 0; }

    const coupon_discount_type = coupon_code.coupon_discount_type;
    const coupon_discount = coupon_code.coupon_discount || 0;

    if (coupon_discount_type === "PERCENTAGE") {
        return total_price * (coupon_discount / 100);
    } else {
        return 0;       // return 0 because we don't want to add the discount to the total discount, because it is only one time / fixed discount.
    }
};

export const calculateTotalWebshop = (record, vat_rates, total_vat, coupon_code) => {

    if (vat_rates === undefined) { vat_rates = []; };
    var total_price = 0;
    var discount = 0;

    total_price = record.product_data.product_pricing.product_pricing[0].price;
    discount = calculateDiscountPercentage(total_price, coupon_code);
    total_vat = getVatPercentage(vat_rates, record.product_data.product_pricing.product_vat_group, total_vat, total_price - discount);

    return {
        total_price: total_price,
        total_vat: total_vat,
        total_discount: discount,
    };

};

export const calculateTotalProductAdvanced = (record, vat_rates, total_vat, coupon_code) => {
    if (vat_rates === undefined) { vat_rates = []; };
    var total_price = 0;
    var discount = 0;

    total_price = record.product_data.product_subscription_data.product_price;
    discount = calculateDiscountPercentage(total_price, coupon_code);
    total_vat = getVatPercentage(vat_rates, record.product_data.product_pricing?.product_vat_group, total_vat, total_price);

    return {
        total_price: total_price,
        total_vat: total_vat,
        total_discount: discount,
    };
};

export const optionPricing = (record) => {
    if (typeof record === 'undefined') { throw new Error("record in optionPricing can not undefined."); }
    if (typeof record.date_range === 'undefined') { throw new Error("record.date_range in optionPricing can not undefined."); }
    if (typeof record.option_data === 'undefined') { throw new Error("record.option_data in optionPricing can not undefined."); }

    var price_found = false;
    var total_price = 0;
    var price_per_interval = 0;
    let tmp_price = 0;
    var total_vat = {};

    var product_time_slots = getRentalPeriod(record.date_range, record.option_data?.product_pricing?.product_time_slots);
    var interval_pricing = product_time_slots?.ts_interval_pricing || [];
    var product_pricing = record.option_data.product_pricing;
    interval_pricing.sort((a, b) => a.interval_amount - b.interval_amount);

    var days_free_of_charge = record.days_free_of_charge;
    var total_interval = calculateInterval(record.date_range, days_free_of_charge, typeof record.date_range === 'string' ? "HOUR" : "DAY");
    var minimal_24hours_order = record.minimal_24hours_order;

    // check we have the 24 minimum only if the product_type is correct
    if (record?.parent_product_data?.product_type === "PRODUCT") {
        if (minimal_24hours_order && total_interval > 0) {
            total_interval = total_interval - 1;
        }
    }

    // check if option is a one time item
    if (product_pricing?.product_one_time_item) {
        price_per_interval = product_pricing.product_pricing[0].price;
        tmp_price = (record.bookdetail_amount * price_per_interval);

        return {
            total_price: tmp_price,
            price_per_interval: price_per_interval,
            total_interval: total_interval,
            total_vat: total_vat,
        };
    }

    // make sure the price array has been sorted by days

    // Check for a direct match in interval_pricing
    for (let index = 0; index < interval_pricing.length; index++) {
        const element = interval_pricing[index];

        if (total_interval === element.interval_amount) {
            price_per_interval = element.interval_price;
            price_found = true;
            tmp_price = (record.bookdetail_amount * (total_interval * price_per_interval));
            total_price = total_price + tmp_price;
            break;
        }
    };

    // not a direct match found in previous loop, look for between values.
    if (!price_found) {
        for (let index = 0; index < interval_pricing.length; index++) {
            const element = interval_pricing[index];

            if (total_interval >= element.interval_amount && total_interval < interval_pricing[index + 1 < product_pricing.length ? index + 1 : index].interval_amount) {
                price_per_interval = element.interval_price;
                tmp_price = (record.bookdetail_amount * (total_interval * price_per_interval));
                total_price = total_price + tmp_price;
                price_found = true;
                break;
            }
        };
    }

    // Price not found probably more day's then the highest option
    if (!price_found) {
        var largest = interval_pricing[0]?.interval_amount;
        var largest_index = 0;

        for (let index = 0; index < interval_pricing.length; index++) {
            const element = interval_pricing[index];

            if (largest < element.interval_amount) {
                largest = element.interval_amount;
                largest_index = index;
            }
        };

        price_per_interval = interval_pricing[largest_index]?.interval_price;
        tmp_price = (record.bookdetail_amount * (total_interval * price_per_interval));
        total_price = total_price + tmp_price;
    }

    return {
        total_price: total_price,
        price_per_interval: price_per_interval,
        total_interval: total_interval,
        total_vat: total_vat,
    };

};

export const calculateInterval = (selectedDate, daysFreeOfCharge, intervalType) => {

    if (!(Array.isArray(selectedDate) && selectedDate.length === 2 && selectedDate.every(item => item instanceof Date))) {
        console.error('selectedDate must a list, contains two dates: [new Date(),new Date()].');
        // throw new Error("date_range in getRentalPeriod is not an instance of Date.");
        return 0;
    }

    if (intervalType === "DAY") {
        // const date1 = dayjs(selectedDate[0], 'DD-MM-YYYY');
        // const date2 = dayjs(selectedDate[1], 'DD-MM-YYYY');
        const date1 = selectedDate[0];
        const date2 = selectedDate[1];

        let dateObj1 = date1;
        const dateObj2 = date2;
        let count_free_of_charge_days = 0;
        let count_total_days = 0;

        // Define a separate function for checking and adding days. This check if we have day's are free of charge
        const addDay = (date) => {
            if (daysFreeOfCharge?.find(day => day.label === dayjs(date).format('dddd').toLowerCase())) {
                count_free_of_charge_days++;
            }
            count_total_days++;
            return dayjs(date).add(1, 'day');
        };

        while (dateObj1 <= dateObj2) {
            // check if the current day is in the object for days free of charge
            dateObj1 = addDay(dateObj1);
        };

        return parseInt(count_total_days - count_free_of_charge_days);
    }
    else if (intervalType === "HOUR") {
        return 1;
    }
};

export const getVatPercentage = (vat_rates, id, total_vat, total_price) => {
    const vat = vat_rates.find((item) => item.vat_id === id)?.vat || 0;

    if (vat > 0) {
        if (total_vat[vat.toString()]) {
            // addup the vat
            total_vat[vat.toString()] = parseFloat(total_vat[vat.toString()]) + (total_price * (vat / 100))
        } else {
            // add new vat item
            total_vat[vat.toString()] = (total_price * (vat / 100))
        }
    }

    return total_vat;

};