import React, { useEffect, useState, } from "react";
import { FormattedMessage, useIntl, } from "react-intl";
import { notification, Switch, } from 'antd';
import { useLocalStorage } from 'usehooks-ts';
import { Controller, } from "react-hook-form";
import { GetCouponCode } from '../../../services/public';
import { CheckCircle, XCircle } from "@phosphor-icons/react";
import { AddNewOrderQuest } from '../../../services/order';
import { calculateTotal, calculateDeposit, } from '../../../components/helpers';
import CartPendingModal from '../../../components/modals/checkout/cart.pending.modal';
import CartNotAvailableModal from '../../../components/modals/checkout/cart.not.available.modal';

// Register new customer
const HomeForm3 = ({
    history,
    submitOrder,
    customerID,
    setOpenPendingModal,
    openPendingModal,
    control,
    setValue,
    watch,
    reset,
    handleSubmit,
    ...props
}) => {

    const [loadingSearch, setLoadingSearch] = useState(false);
    const [pendingNewOrder, setPendingNewOrder] = useState(true);
    const [couponCodeStatus, setCouponCodeStatus] = useLocalStorage('coupon_code_sloep', { status: false, data: {} });
    const [couponCodeInput, setCouponCodeInput] = useLocalStorage('coupon_code_sloep_input', '');
    const [userForm] = useLocalStorage('user_form_sloep_input', {});
    const [notAvailableModal, setNotAvailableModal] = useState(false);
    const [notAvailableData, setNotAvailableData] = useState({});
    const [maxTotalPeople, setMaxTotalPeople] = useState(0);
    const [cartTotal, setCartTotal] = useState({ deposit_total_price: 0, sub_total_price: 0, total_vat: {} });
    const [vatRate, setVatRate] = useState(undefined);
    const [shoppingCart] = useLocalStorage('shoppingcart_sloep', []);
    const intl = useIntl();
    const { GAPurchaseEvent, themeData: { branding_financial } } = props;
    const email = watch("email");
    const user_form = watch();

    // check if there is a user form in the localstorage
    useEffect(() => {
        if (userForm) {
            reset(userForm);
        }

        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        const { vat_rates, } = branding_financial;
        if (vat_rates) { setVatRate(vat_rates); }
        // eslint-disable-next-line
    }, [branding_financial]);

    // save the user form in the localstorage
    useEffect(() => {
        if (user_form) {
            localStorage.setItem('user_form_sloep_input', JSON.stringify(user_form));
        }

        // eslint-disable-next-line
    }, [user_form]);

    // set the max amount of people
    useEffect(() => {
        window.scrollTo({ top: 0 });
        let tmp_amount_people = 0;

        for (let index = 0; index < shoppingCart.length; index++) {
            const element = shoppingCart[index];
            const fields = element.product_data.product_custom_fields
            for (let index = 0; index < fields.length; index++) {
                const element = fields[index];
                if (element.field_placeholder === "aantal_personen") {
                    tmp_amount_people += parseInt(element?.field_value);
                }
            };
        };
        setMaxTotalPeople(tmp_amount_people);

    }, [shoppingCart]);

    useEffect(() => {
        calculateCartTotal();

        // eslint-disable-next-line
    }, [shoppingCart, vatRate]);

    useEffect(() => {
        if (customerID) {
            shoppingcartOrder();
            // checkPaymentDirectDeposit();
        }
        // eslint-disable-next-line
    }, [customerID]);

    // generete the amount of people used for the pulldown
    const people_amount = [];
    for (let i = 1; i < maxTotalPeople + 1; i++) {
        people_amount.push({
            label: i,
            value: i,
            // disabled: i === 10,
        });
    };

    // const testGoogleAnalytics = () => {
    //     const ga_items = [];
    //     for (let index = 0; index < shoppingCart.length; index++) {
    //         const element = shoppingCart[index];
    //         console.log('element: ', element);
    //         ga_items.push({
    //             item_id: element.product_data.product_id,
    //             item_name: element.product_data.product_names.product_name_nl,
    //             // discount: 2.22,
    //             // index: 0,
    //             // location_id: "ChIJIQBpAG2ahYAR_6128GcTUEo",
    //             price: calculateTotal(element, vatRate).total_price,
    //             quantity: element.bookdetail_amount,
    //             item_category1: element.product_data.product_sku,   // Type sloep
    //             item_category2: element.bookdetail_amount,      // Aantal sloepen
    //             item_category3: element.interval_amount,        // Vaarduur
    //         });
    //     };
    //     console.log('ga_items: ', ga_items);
    // };

    const shoppingcartOrder = () => {

        setOpenPendingModal(true);
        // setValue("include_deposit_payment", false);
        var postdata = {
            shoppingCart,
            coupon_code: couponCodeInput,
            customer_id: customerID,
            email: email,
            booking_note: user_form.booking_note,
            include_deposit_payment: false,
        };

        AddNewOrderQuest(postdata).then(response => {
            if (response.status) {
                setPendingNewOrder(false);
                notification['success']({
                    message: 'Shoppingcart',
                    description: intl.formatMessage({ id: response.msg }),
                });

                // clear shoppingcart
                localStorage.removeItem('shoppingcart_sloep'); // On customer request, dont clear the shoppingcart
                localStorage.removeItem('coupon_code_sloep');
                localStorage.removeItem('coupon_code_sloep_input');

                const booking_id = response.data.booking_id;
                const booking_number = response.data.booking_number;

                // Als we de items kunnen onderverdelen in item categories, dan graag de volgende categorieën:
                // item_category1: Type sloep
                // item_category2: Aantal sloepen
                // item_category3: Vaarduur
                // item_category4: Koelbox
                // item_category5: Arrangementen

                // building items object for Google Analytics
                // https://developers.google.com/analytics/devguides/collection/ga4/reference/events?sjid=12649721557544184366-EU&client_type=gtag#purchase
                const ga_items = [];
                for (let index = 0; index < shoppingCart.length; index++) {
                    const element = shoppingCart[index];
                    if (element.bookdetail_amount > 0) {
                        ga_items.push({
                            item_id: element.product_data.product_id,
                            item_name: element.product_data.product_names.product_name_nl,
                            // discount: 2.22,
                            // index: 0,
                            // location_id: "ChIJIQBpAG2ahYAR_6128GcTUEo",
                            price: calculateTotal(element, vatRate).total_price,
                            quantity: element.bookdetail_amount,
                            item_category1: element.product_data.product_sku,   // Type sloep
                            item_category2: element.bookdetail_amount,      // Aantal sloepen
                            item_category3: element.interval_amount,        // Vaarduur
                        });
                    }
                };

                const ga_event = {
                    label: 'purchase',
                    transaction_id: booking_id,
                    booking_number: booking_number,
                    value: cartTotal.sub_total_price,
                    tax: cartTotal.total_vat.vat_21,
                    shipping: 0,
                    currency: "EUR",
                    coupon: couponCodeInput,
                    items: ga_items,
                };
                // console.log('ga_event: ', ga_event);
                // Send Google GA Event
                GAPurchaseEvent(ga_event);

                // Redirect to payment page
                setTimeout(() => {
                    setOpenPendingModal(false);
                    history(`/payment/${response.data.payment_hash}`);
                }, 2500);

            } else {
                // If item is not availible anymore
                if (response.msg === "ITEM_NOT_AVAILIBLE") {
                    notification['error']({
                        message: 'Shoppingcart',
                        description: intl.formatMessage({ id: response.msg }),
                    });
                    setPendingNewOrder(true);
                    setOpenPendingModal(false);
                    setNotAvailableData([response.data], setNotAvailableModal(true));
                }
            }
        })
    };

    const handleCouponCodeInput = () => {
        setLoadingSearch(true);
        GetCouponCode({ coupon_code: couponCodeInput }).then(response => {
            setCouponCodeStatus({ checked: true, ...response })
            setLoadingSearch(false);
        });
    };

    const calculateCartTotal = () => {
        // setLoadingCartTotal(true);
        let sub_total_price = 0;
        let deposit_total_price = 0;
        let total_vat = {};

        for (let index = 0; index < shoppingCart.length; index++) {
            const record = shoppingCart[index];
            record.date_range = [new Date(record.date_range[0]), new Date(record.date_range[1])];
            const results = calculateTotal(record, vatRate, couponCodeStatus.data);
            const deposit = calculateDeposit(record);
            deposit_total_price += deposit;

            // Merge new vat into existing total_vat object
            for (const [key, value] of Object.entries(results.total_vat)) {
                if (total_vat.hasOwnProperty(key)) {
                    total_vat[key] += value;
                } else {
                    total_vat[key] = value;
                }
            };

            sub_total_price += results.total_price;
        };

        setCartTotal({ deposit_total_price, sub_total_price, total_vat });
        // setLoadingCartTotal(false);
    };

    // const checkPaymentDirectDeposit = () => {

    //     // check for payment_direct_deposit_disabled
    //     if (branding_financial.payment_direct_deposit_disabled) {
    //         console.log('payment_direct_deposit_disabled YES');

    //         // if deposit is 0 then submit the shoppingcart
    //         if (cartTotal.deposit_total_price === 0) {
    //             handleSubmit(shoppingcartOrder)();
    //             return true;
    //         }

    //         // show modal to choose the option the pay the deposit on a later moment.
    //         for (let index = 0; index < shoppingCart.length; index++) {
    //             const element = shoppingCart[index];

    //             // Extract the start date from date_range array
    //             const startdate = element.date_range[0];

    //             // Calculate the time 48 hours from now
    //             const now = new Date();
    //             const fortyEightHoursLater = new Date(now.getTime() + 48 * 60 * 60 * 1000);

    //             // Check if startdate is smaller than 48 hours
    //             if (fortyEightHoursLater > startdate) {
    //                 console.warn('Start date is larger than 48 hours, adding deposit');
    //                 setValue("include_deposit_payment", true);
    //             } else {
    //                 setValue("include_deposit_payment", false);
    //             }
    //         }
    //     } else {
    //         setValue("include_deposit_payment", true);
    //     }
    //     handleSubmit(shoppingcartOrder)();
    // };

    return (
        <React.Fragment>
            <section className="column-container sloep-form-steps fourth-step">
                <div className="column-container-wrapper">
                    <div className="col-full">
                        <h2
                            className="content-title weight-bold default-color uppercase margin-b-xxl phone-m-display-flex">
                            <span className="display-inline-flex flex-center-center border-rounder bg-secondary radius-round step-number-wrapper">
                                <i className="step-number">3</i>
                            </span>
                            <span className="default-color size-h2 weight-bold flex-auto flex-center-center">
                                <FormattedMessage id="personal-details" />
                            </span>
                        </h2>
                    </div>
                </div>

                <div className="column-container-wrapper">

                    {/*  salutation */}
                    {/* <h3 className="content-title size-body weight-regular default-color">
                        <FormattedMessage id="salutation" />
                    </h3>
                    <div className="personen__inputs display-flex margin-b-xl">
                        <div className="personen__inputs-wrapper display-flex phone-l-width-full margin-b-s">
                            <input type="radio" id="dhr" name="aanhef" className="hide input-form" value="Dhr." />
                            <label htmlFor="dhr" className="cursor-pointer"><span>
                                <FormattedMessage id="dhr" />
                            </span></label><br />
                        </div>
                        <div className="personen__inputs-wrapper display-flex phone-l-width-full margin-b-s">
                            <input type="radio" id="mevr" name="aanhef" className="hide input-form" value="Mevr." />
                            <label htmlFor="mevr" className="cursor-pointer"><span>
                                <FormattedMessage id="mevr" />
                            </span></label><br />
                        </div>
                    </div> */}

                    {/* first name */}
                    <h3 className="content-title size-body weight-regular default-color">
                        <FormattedMessage id="first-name" />
                    </h3>
                    <FormattedMessage id="first-name">
                        {NameField =>
                            <Controller
                                control={control}
                                defaultValue=""
                                name="first_name"
                                rules={{
                                    required: { value: true, message: "inputfield-FieldIsRequired" },
                                    // minLength: { value: 8, message: "inputfield-minLength8" },
                                    // pattern: {
                                    //     value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).+$/,
                                    //     message: "PasswordMustContainsNumbersLettersUppercaseLowercase",
                                    // },
                                }}
                                render={({
                                    field: { onChange, onBlur, value, name, ref },
                                    fieldState: { error },
                                }) => (
                                    <div className="personen__inputs-wrapper display-flex margin-b-xl"
                                        // validateStatus={error?.message && "error"}
                                        label={<strong>{NameField}</strong>}
                                        required>
                                        <input
                                            placeholder={NameField}
                                            autoComplete="off"
                                            // prefix={<LockSimple size={18} weight="light" className="site-form-item-icon" />}
                                            onChange={onChange}
                                            value={value}
                                            className="input-form weight-regular flex-2 calc-gap-l phone-l-width-full"
                                        />
                                        {error && <p>{intl.formatMessage({ id: error?.message })}</p>}
                                    </div>
                                )}
                            />
                        }
                    </FormattedMessage>

                    {/* Last name field */}
                    <h3 className="content-title size-body weight-regular default-color">
                        <FormattedMessage id="last-name" />
                    </h3>
                    <FormattedMessage id="last-name">
                        {NameField =>
                            <Controller
                                control={control}
                                defaultValue=""
                                name="last_name"
                                rules={{
                                    required: { value: true, message: "inputfield-FieldIsRequired" },
                                    // minLength: { value: 8, message: "inputfield-minLength8" },
                                    // pattern: {
                                    //     value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).+$/,
                                    //     message: "PasswordMustContainsNumbersLettersUppercaseLowercase",
                                    // },
                                }}
                                render={({
                                    field: { onChange, onBlur, value, name, ref },
                                    fieldState: { error },
                                }) => (
                                    <div className="personen__inputs-wrapper display-flex margin-b-xl"
                                        // validateStatus={error?.message && "error"}
                                        label={<strong>{NameField}</strong>}
                                        required>
                                        <input
                                            placeholder={NameField}
                                            autoComplete="off"
                                            onChange={onChange}
                                            value={value}
                                            className="input-form weight-regular flex-2 calc-gap-l phone-l-width-full"
                                        />
                                        {error && <p>{intl.formatMessage({ id: error?.message })}</p>}
                                    </div>
                                )}
                            />
                        }
                    </FormattedMessage>

                    {/* Email address */}
                    <h3 className="content-title size-body weight-regular default-color">
                        <FormattedMessage id="email" />
                    </h3>
                    <FormattedMessage id="email">
                        {NameField =>
                            <Controller
                                control={control}
                                defaultValue=""
                                name="email"
                                rules={{
                                    required: { value: true, message: "inputfield-FieldIsRequired" },
                                    pattern: {
                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,24}$/i,
                                        message: "EmailAddressNotValid",
                                    },
                                }}
                                render={({
                                    field: { onChange, onBlur, value, name, ref },
                                    fieldState: { error },
                                }) => (
                                    <div className="personen__inputs-wrapper display-flex margin-b-xl"
                                        // validateStatus={error?.message && "error"}
                                        label={<strong>{NameField}</strong>}
                                        required>
                                        <input
                                            placeholder={NameField}
                                            autoComplete="off"
                                            onChange={onChange}
                                            value={value}
                                            className="input-form weight-regular flex-2 calc-gap-l phone-l-width-full"
                                        />
                                        {error && <p>{intl.formatMessage({ id: error?.message })}</p>}
                                    </div>
                                )}
                            />
                        }
                    </FormattedMessage>

                    {/* Phone */}
                    <h3 className="content-title size-body weight-regular default-color">
                        <FormattedMessage id="phone" />
                    </h3>
                    <FormattedMessage id="phone">
                        {NameField =>
                            <Controller
                                control={control}
                                defaultValue=""
                                name="phone_number"
                                rules={{
                                    required: { value: true, message: "inputfield-FieldIsRequired" },
                                    // minLength: { value: 8, message: "inputfield-minLength8" },
                                    // pattern: {
                                    //     value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).+$/,
                                    //     message: "PasswordMustContainsNumbersLettersUppercaseLowercase",
                                    // },
                                }}
                                render={({
                                    field: { onChange, onBlur, value, name, ref },
                                    fieldState: { error },
                                }) => (
                                    <div className="personen__inputs-wrapper display-flex margin-b-xl"
                                        // validateStatus={error?.message && "error"}
                                        label={<strong>{NameField}</strong>}
                                        required>
                                        <input
                                            placeholder={NameField}
                                            autoComplete="off"
                                            // prefix={<LockSimple size={18} weight="light" className="site-form-item-icon" />}
                                            onChange={onChange}
                                            value={value}
                                            className="input-form weight-regular flex-2 calc-gap-l phone-l-width-full"
                                        />
                                        {error && <p>{intl.formatMessage({ id: error?.message })}</p>}
                                    </div>
                                )}
                            />
                        }
                    </FormattedMessage>

                    {/* Amount of people */}
                    <h3 className="content-title size-body weight-regular default-color">
                        <FormattedMessage id="amount-people" />
                    </h3>
                    <FormattedMessage id="amount-people">
                        {NameField =>
                            <Controller
                                control={control}
                                defaultValue=""
                                name="amount_people"
                                rules={{
                                    required: { value: true, message: "inputfield-FieldIsRequired" },
                                }}
                                render={({
                                    field: { onChange, onBlur, value, name, ref },
                                    fieldState: { error },
                                }) => (
                                    <div className="personen__inputs-wrapper display-flex margin-b-xl"
                                        // validateStatus={error?.message && "error"}
                                        label={<strong>{NameField}</strong>}
                                        required>
                                        <select
                                            onChange={onChange}
                                            value={value}
                                            // name="amount_people"
                                            className="input-form flex-2 calc-gap-l phone-l-width-full weight-regular">
                                            <option value="0"><FormattedMessage id="make-choice" /></option>
                                            {people_amount.map((item, i) => (
                                                <option key={i} value={item.value}>{item.label}</option>
                                            ))}
                                        </select>
                                        {error && <p>{intl.formatMessage({ id: error?.message })}</p>}
                                    </div>
                                )}
                            />
                        }
                    </FormattedMessage>

                    {/* Notes */}
                    <h3 className="content-title size-body content-title size-body weight-regular default-color">
                        <FormattedMessage id="note" />
                    </h3>
                    <FormattedMessage id="note">
                        {NameField =>
                            <Controller
                                control={control}
                                defaultValue=""
                                name="booking_note"
                                rules={{
                                    // required: { value: true, message: "inputfield-FieldIsRequired" },
                                    // minLength: { value: 8, message: "inputfield-minLength8" },
                                    // pattern: {
                                    //     value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).+$/,
                                    //     message: "PasswordMustContainsNumbersLettersUppercaseLowercase",
                                    // },
                                }}
                                render={({
                                    field: { onChange, onBlur, value, name, ref },
                                    fieldState: { error },
                                }) => (
                                    <div className="personen__inputs-wrapper display-flex margin-b-xl"
                                        // validateStatus={error?.message && "error"}
                                        required>
                                        <textarea
                                            style={{ height: 'inherit !important' }}
                                            onChange={onChange}
                                            value={value}
                                            rows="3"
                                            placeholder={NameField}
                                            className="input-form flex-65 calc-gap-l phone-l-width-full weight-regular padding-tb-s">
                                        </textarea>

                                        <p>{error && intl.formatMessage({ id: error?.message })}</p>
                                    </div>
                                )}
                            />
                        }
                    </FormattedMessage>

                    {/* Check coupon code */}
                    <h3 className="content-title size-body content-title size-body weight-regular default-color">
                        <FormattedMessage id="vouchercode" />
                    </h3>
                    <FormattedMessage id="vouchercode">
                        {NameField =>
                            <div className="personen__inputs-wrapper display-flex margin-b-xl"
                                // validateStatus={error?.message && "error"}
                                label={<strong>{NameField}</strong>}
                                required>
                                <input
                                    placeholder={NameField}
                                    autoComplete="off"
                                    disabled={loadingSearch}
                                    // prefix={<LockSimple size={18} weight="light" className="site-form-item-icon" />}
                                    onChange={e => setCouponCodeInput(e.target.value.toUpperCase())}
                                    value={couponCodeInput}
                                    className="input-form weight-regular flex-30 calc-gap-l me-1"
                                />
                                <button
                                    disabled={loadingSearch}
                                    onClick={handleCouponCodeInput}
                                    className={`${loadingSearch && 'disable-bg'} slv-button flex-4 step__cta-submit-btn bg-secondary hover-bg-darkblue white-color weight-semibold`}>
                                    <FormattedMessage id="check" />
                                </button>

                                {couponCodeStatus.checked && !couponCodeStatus.status && (
                                    <div className="mt-2 text-danger text-center"><XCircle size={16} className="icon" /> <FormattedMessage id="coupon-code-not-found" /></div>
                                )}

                                {couponCodeStatus.checked && couponCodeStatus.status && (
                                    <div className="mt-2 text-success text-center"><CheckCircle size={16} className="icon" /> <FormattedMessage id="coupon-code-applied" /></div>
                                )}

                            </div>
                        }
                    </FormattedMessage>

                    {/* agree_terms */}
                    <h3 className="content-title size-body weight-regular default-color">
                        <FormattedMessage id="agree-terms" />
                    </h3>
                    <Controller
                        control={control}
                        defaultValue={false}
                        name="agree_terms"
                        rules={{
                            required: { value: true, message: "inputfield-FieldIsRequired" },
                        }}
                        render={({
                            field: { onChange, onBlur, value, name, ref },
                            fieldState: { error },
                        }) => (
                            <div className="personen__inputs-wrapper display-flex margin-b-xl">
                                <Switch
                                    onChange={onChange}
                                    checked={value}
                                // name="amount_people"
                                // className="input-form flex-2 calc-gap-l phone-l-width-full weight-regular"
                                />
                                {error && <p>{intl.formatMessage({ id: error?.message })}</p>}
                            </div>
                        )}
                    />

                    {/* agree_mailling_list */}
                    <h3 className="content-title size-body weight-regular default-color">
                        <FormattedMessage id="agree-mailling-list" />
                    </h3>
                    <Controller
                        control={control}
                        defaultValue={true}
                        name="agree_mailling_list"
                        rules={{
                            // required: { value: true, message: "inputfield-FieldIsRequired" },
                        }}
                        render={({
                            field: { onChange, onBlur, value, name, ref },
                            fieldState: { error },
                        }) => (
                            <div className="personen__inputs-wrapper display-flex margin-b-xl">
                                <Switch
                                    onChange={onChange}
                                    checked={value}
                                // name="amount_people"
                                // className="input-form flex-2 calc-gap-l phone-l-width-full weight-regular"
                                />
                                {error && <p>{intl.formatMessage({ id: error?.message })}</p>}
                            </div>
                        )}
                    />
                </div>

                {/* Order buttons show only in the last step */}
                <div className="column-container-wrapper second-step__cta-nav hide-tablet">
                    <div className="col-full display-flex flex-between">
                        <button type="button"
                            onClick={e => history('/arrangementen')}
                            className="slv-button step__cta-nav-btn bg-secondary hover-bg-darkblue white-color weight-semibold margin-b-m">
                            <FormattedMessage id="previous" />
                        </button>
                        <button
                            onClick={handleSubmit(submitOrder)}
                            type="button"
                            className="slv-button step__cta-nav-btn book-btn bg-secondary white-color weight-semibold margin-b-m">
                            <FormattedMessage id="pay-and-reserve" />
                        </button>
                    </div>
                </div>
            </section>

            {openPendingModal && (
                <CartPendingModal
                    modalIsOpenHandler={setOpenPendingModal}
                    modalIsOpen={openPendingModal}
                    loading={pendingNewOrder}
                    {...props} />
            )}

            {notAvailableModal && (
                <CartNotAvailableModal
                    modalIsOpen={notAvailableModal}
                    modalIsOpenHandler={setNotAvailableModal}
                    notAvailableData={notAvailableData}
                    {...props}
                />
            )}

            {/* {openPaymentDepositModal && (
                <CartPaymentDepositModal
                    modalIsOpen={openPaymentDepositModal}
                    modalIsOpenHandler={setOpenPaymentDepositModal}
                    shoppingcartOrder={shoppingcartOrder}
                    setValue={setValue}
                    handleSubmit={handleSubmit}
                    {...props}
                />
            )} */}

        </React.Fragment>
    );
};

export default HomeForm3;