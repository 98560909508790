import React, { useState, } from "react";
import { Form, Input, notification, Row, Col, Switch, Select, Result, Image, } from 'antd';
import { LoadingOutlined, } from '@ant-design/icons';
import { FormattedMessage, useIntl } from "react-intl";
import { useForm, Controller } from "react-hook-form";

import { Register } from '../../../services/register';
import { CheckUserExists } from '../../../services/users';
import { COUNTRIES } from '../../../constants/countries';  // default for Netherlands = nl
import { CaretLeft, EnvelopeSimple, } from "@phosphor-icons/react";

const SignUpFormView = ({
    modalIsOpenHandler,
    setShowForm,
    history,
    formType,   // on of the type "SIGN_UP" or "SIGN_UP_ONSITE"
    setValue,
    processOrder,
    ...props
}) => {

    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [succesSignUp, setSuccesSignUp] = useState(false);
    const [customerID, setCustomerID] = useState(null);
    const intl = useIntl();
    const { language } = props;
    const { handleSubmit, control, watch } = useForm(
        { mode: "all" }, // set the field validation mode
    );

    const company_account = watch("company_account");

    const onSubmit = (data) => {
        const post_data = {
            customer_type: company_account ? "COMPANY" : "CONSUMER",
            agree_privacy_statement: false,
            agree_terms: false,
            agree_mailling_list: false,
            customer_name: company_account ? data.company_name : `${data.user_first_name} ${data.user_last_name}`,
            email: data.user_email,
            first_name: data.user_first_name,
            last_name: data.user_last_name,
            language: language,
            phone_number: data.phone_number,
            ...data
        };

        setButtonDisabled(true);

        Register(post_data).then(response => {
            if (response.status) {
                notification['success']({
                    message: 'Sign-up',
                    description: intl.formatMessage({ id: response.msg }),
                });
                // save the customer_id, need to retrive the QR code
                setCustomerID(response.data.customer_id);
                // Save the customer_id also for processing the order
                setValue("customer_id", response.data.customer_id);

                if (formType === "SIGN_UP") {
                    // set some delay to process the order
                    setTimeout(() => {
                        processOrder();
                        setButtonDisabled(false);
                        modalIsOpenHandler(false);
                    }, 250);

                } else {
                    // if formType === "SIGN_UP_ONSITE" then show the QR-code
                    setSuccesSignUp(true);
                }
            } else {
                setButtonDisabled(false);
                notification['error']({
                    message: 'Sign-up',
                    description: intl.formatMessage({ id: response.msg }),
                });
            }
        })
            .catch(error => {
                console.error('UserLogin error: ', error);

                notification['error']({
                    message: 'Inloggen',
                    description: "Oeps something is wrong: Cannot connect to authentication server!",
                });

                setButtonDisabled(false);
                // setButtonIcon(faSignInAlt);

            })
    };

    const filterOption = (input, option) => {
        return option.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
            option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    };


    return (
        <React.Fragment>
            {succesSignUp ? (
                <Result
                    className="result-background"
                    status={"success"}
                    title={<FormattedMessage id="account-registration-done" />}
                    subTitle={<FormattedMessage id="account-registration-done-info" />}
                    extra={[
                    ]}>
                    <div className="text-center">
                        <Image
                            width={250}
                            preview={false}
                            src={`https://${process.env.REACT_APP_AWS_DATASTORE}/data/files/${customerID}/qr_code.png`}
                        />
                    </div>

                </Result>
            ) : (
                <React.Fragment>

                    {/* Account selection */}
                    <FormattedMessage id="company_account">
                        {NameField =>
                            <Controller
                                control={control}
                                defaultValue=""
                                name="company_account"
                                // rules={{
                                //     required: { value: true, message: "inputfield-FieldIsRequired" },
                                // }}
                                render={({
                                    field: { onChange, onBlur, value, name, ref },
                                    fieldState: { error },
                                }) => (
                                    <Form.Item
                                        // help={error && intl.formatMessage({ id: error?.message })}
                                        // hasFeedback
                                        // validateStatus={error?.message && "error"}
                                        label={<strong>{NameField}</strong>}
                                    // required
                                    >
                                        <Switch
                                            onChange={onChange}
                                            value={value}
                                            placeholder={NameField}
                                        // onChange={(e) => { props.onChange(e) }}
                                        // checked={props.value}
                                        /> <small><FormattedMessage id="company-account-info" /></small>
                                    </Form.Item>
                                )}
                            />
                        }
                    </FormattedMessage>

                    {/* First & Last name */}
                    <Form.Item
                        label={<strong><FormattedMessage id={company_account ? ("contact-person") : ("customer-name")} /></strong>}
                        required
                        style={{ marginBottom: 0 }}>
                        <FormattedMessage id="first-name">
                            {NameField =>
                                <Controller
                                    control={control}
                                    defaultValue=""
                                    name="user_first_name"
                                    rules={{ required: { value: true, message: "inputfield-FieldIsRequired" } }}
                                    render={({
                                        field: { onChange, onBlur, value, name, ref },
                                        fieldState: { error },
                                    }) => (
                                        <Form.Item
                                            help={error && intl.formatMessage({ id: error?.message })}
                                            hasFeedback
                                            validateStatus={error?.message && "error"}
                                            required
                                            style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}
                                        // label={<FormattedMessage id="firstname-and-lastname" />}
                                        >
                                            <Input
                                                placeholder={NameField}
                                                onChange={onChange}
                                                value={value}
                                                autoComplete="on"
                                            />
                                        </Form.Item>
                                    )}
                                />}
                        </FormattedMessage>

                        <FormattedMessage id="last-name">
                            {NameField =>
                                <Controller
                                    control={control}
                                    defaultValue=""
                                    name="user_last_name"
                                    rules={{ required: { value: true, message: "inputfield-FieldIsRequired" } }}
                                    render={({
                                        field: { onChange, onBlur, value, name, ref },
                                        fieldState: { error },
                                    }) => (
                                        <Form.Item
                                            help={error && intl.formatMessage({ id: error?.message })}
                                            hasFeedback
                                            validateStatus={error?.message && "error"}
                                            required
                                            style={{ display: 'inline-block', width: 'calc(50% - 8px)', margin: '0 8px' }}>
                                            <Input
                                                placeholder={NameField}
                                                onChange={onChange}
                                                value={value}
                                                autoComplete="on"
                                            />
                                        </Form.Item>
                                    )}
                                />}
                        </FormattedMessage>
                    </Form.Item>

                    {company_account && (
                        <React.Fragment>
                            <FormattedMessage id="company-name">
                                {NameField =>
                                    <Controller
                                        control={control}
                                        defaultValue=""
                                        name="company_name"
                                        rules={{
                                            required: { value: true, message: "inputfield-FieldIsRequired" },
                                        }}
                                        render={({
                                            field: { onChange, onBlur, value, name, ref },
                                            fieldState: { error },
                                        }) => (
                                            <Form.Item
                                                help={error && intl.formatMessage({ id: error?.message })}
                                                hasFeedback
                                                validateStatus={error?.message && "error"}
                                                label={<strong>{NameField}</strong>}
                                                required>
                                                <Input
                                                    onChange={onChange}
                                                    value={value}
                                                    placeholder={NameField}
                                                    autoComplete="on"
                                                // onChange={(e) => { props.onChange(e) }}
                                                // checked={props.value}
                                                />
                                            </Form.Item>
                                        )}
                                    />
                                }
                            </FormattedMessage>
                        </React.Fragment>
                    )}

                    {/* Street and number */}
                    <FormattedMessage id="street-number">
                        {NameField =>
                            <Controller
                                control={control}
                                defaultValue=""
                                name="street_number"
                                rules={{
                                    required: { value: true, message: "inputfield-FieldIsRequired" },
                                }}
                                render={({
                                    field: { onChange, onBlur, value, name, ref },
                                    fieldState: { error },
                                }) => (
                                    <Form.Item
                                        help={error && intl.formatMessage({ id: error?.message })}
                                        hasFeedback
                                        validateStatus={error?.message && "error"}
                                        label={<strong>{NameField}</strong>}
                                        required>
                                        <Input
                                            onChange={onChange}
                                            value={value}
                                            placeholder={NameField}
                                            autoComplete="on"
                                        // onChange={(e) => { props.onChange(e) }}
                                        // checked={props.value}
                                        />
                                    </Form.Item>
                                )}
                            />
                        }
                    </FormattedMessage>

                    {/* postcode and city */}
                    <Form.Item
                        label={<strong><FormattedMessage id="zip-city" /></strong>}
                        required
                        style={{ marginBottom: 0 }}>
                        <FormattedMessage id="zip">
                            {NameField =>
                                <Controller
                                    control={control}
                                    defaultValue=""
                                    name="zip_code"
                                    rules={{ required: { value: true, message: "inputfield-FieldIsRequired" } }}
                                    render={({
                                        field: { onChange, onBlur, value, name, ref },
                                        fieldState: { error },
                                    }) => (
                                        <Form.Item
                                            help={error && intl.formatMessage({ id: error?.message })}
                                            hasFeedback
                                            validateStatus={error?.message && "error"}
                                            required
                                            style={{ display: 'inline-block', width: 'calc(30% - 8px)' }}
                                        // label={<FormattedMessage id="firstname-and-lastname" />}
                                        >
                                            <Input
                                                placeholder={NameField}
                                                onChange={onChange}
                                                value={value}
                                                autoComplete="on"
                                            />
                                        </Form.Item>
                                    )}
                                />}
                        </FormattedMessage>

                        <FormattedMessage id="city">
                            {NameField =>
                                <Controller
                                    control={control}
                                    defaultValue=""
                                    name="city"
                                    rules={{ required: { value: true, message: "inputfield-FieldIsRequired" } }}
                                    render={({
                                        field: { onChange, onBlur, value, name, ref },
                                        fieldState: { error },
                                    }) => (
                                        <Form.Item
                                            help={error && intl.formatMessage({ id: error?.message })}
                                            hasFeedback
                                            validateStatus={error?.message && "error"}
                                            required
                                            style={{ display: 'inline-block', width: 'calc(70% - 8px)', margin: '0 8px' }}>
                                            <Input
                                                placeholder={NameField}
                                                onChange={onChange}
                                                value={value}
                                                autoComplete="on"
                                            />
                                        </Form.Item>
                                    )}
                                />}
                        </FormattedMessage>
                    </Form.Item>

                    {/* Country */}
                    <FormattedMessage id="country">
                        {NameField =>
                            <Controller
                                control={control}
                                defaultValue="nl"
                                name="country"
                                rules={{ required: { value: true, message: "inputfield-FieldIsRequired" } }}
                                render={({
                                    field: { onChange, onBlur, value, name, ref },
                                    fieldState: { error },
                                }) => (
                                    <Form.Item
                                        help={error && intl.formatMessage({ id: error?.message })}
                                        hasFeedback
                                        validateStatus={error?.message && "error"}
                                        label={<strong>{NameField}</strong>}
                                        required>
                                        <Select
                                            showSearch
                                            filterOption={filterOption}
                                            value={value}
                                            onChange={(e) => { onChange(e) }}>
                                            <Select.Option value="">{intl.formatMessage({ id: 'make-choice' })}</Select.Option>

                                            {COUNTRIES.map(title => (
                                                <Select.Option key={title.code} value={title.code}>
                                                    {title.name}
                                                </Select.Option>
                                            ))}

                                        </Select>
                                    </Form.Item>
                                )}
                            />
                        }
                    </FormattedMessage>

                    {/* phone number */}
                    <FormattedMessage id="phonenumber">
                        {NameField =>
                            <Controller
                                control={control}
                                defaultValue=""
                                name="phone_number"
                                rules={{ required: { value: true, message: "inputfield-FieldIsRequired" } }}
                                render={({
                                    field: { onChange, onBlur, value, name, ref },
                                    fieldState: { error },
                                }) => (
                                    <Form.Item
                                        help={error && intl.formatMessage({ id: error?.message })}
                                        hasFeedback
                                        validateStatus={error?.message && "error"}
                                        required
                                        label={<strong>{NameField}</strong>}>
                                        <Input
                                            placeholder={NameField}
                                            onChange={onChange}
                                            value={value}
                                            autoComplete="on"
                                        />
                                    </Form.Item>
                                )}
                            />
                        }
                    </FormattedMessage>

                    {/* mail address */}
                    <FormattedMessage id="emailaddress">
                        {NameField =>
                            <Controller
                                control={control}
                                defaultValue=""
                                name="user_email"
                                rules={{
                                    required: { value: true, message: "inputfield-FieldIsRequired" },
                                    pattern: {
                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,24}$/i,
                                        message: "EmailAddressNotValid",
                                    },
                                    validate: {
                                        checkemail: async (value) => {
                                            if (!value) { return true };
                                            return CheckUserExists({ user_email: value }).then(response => {
                                                if (response.data.email_exists) { return 'EmailAddressAlreadyExist' }
                                            });
                                        }
                                    }
                                }}
                                render={({
                                    field: { onChange, onBlur, value, name, ref },
                                    fieldState: { error },
                                }) => (
                                    <Form.Item
                                        help={error && intl.formatMessage({ id: error?.message })}
                                        hasFeedback
                                        validateStatus={error?.message && "error"}
                                        label={<strong>{NameField}</strong>}
                                        required>
                                        <Input
                                            onChange={onChange}
                                            value={value}
                                            placeholder={NameField}
                                            prefix={<EnvelopeSimple size={18} weight="light" className="site-form-item-icon" />}
                                            autoComplete="on"
                                        // onChange={(e) => { props.onChange(e) }}
                                        // checked={props.value}
                                        />
                                    </Form.Item>
                                )}
                            />
                        }
                    </FormattedMessage>

                    <div className="text-center mt-4">
                        <div className="btn__box">
                            <button
                                onClick={handleSubmit(onSubmit)}
                                className="slv-button white-color weight-semibold bg-secondary pointer mt-4"
                                disabled={buttonDisabled}>
                                <span className="txt text-center">
                                    {buttonDisabled ? (
                                        <LoadingOutlined />) : (
                                        <FormattedMessage id="signup" />
                                    )}
                                </span>
                            </button>
                        </div>
                    </div>

                    <Row className="mt-3 login-footer">
                        <Col span={24}><CaretLeft size={18} weight="light" /> <span className="pointer" onClick={e => setShowForm("LOGIN")}><FormattedMessage id="back" /></span></Col>
                    </Row>

                </React.Fragment>
            )}
        </React.Fragment>
    );
};

export default SignUpFormView;