import React, { useEffect, useState, } from "react";
import { useLocation, } from 'react-router-dom';
import { FormattedMessage, } from "react-intl";
import { GetProducts, GetCategories, } from '../../../services/products';
import HomeForm1 from '../detail.views/home.form.1';
import HomeForm2 from '../detail.views/home.form.2';
import HomeForm3 from '../detail.views/home.form.3';

const HomeLeftView = ({
    history,
    formStep,
    submitOrder,
    customerID,
    setOpenPendingModal,
    openPendingModal,
    handleSubmit,
    control,
    setValue,
    watch,
    reset,
    ...props
}) => {

    const [productList, setProductList] = useState(undefined);
    const [categories, setCategories] = useState(undefined);
    const [loadingData, setLoadingData] = useState(true);
    const { state } = useLocation();

    useEffect(() => {
        getProducts();
        getCategories();

        return () => {
            // cleanup
        }
        // below line needed to remove the warning on build.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state]);

    useEffect(() => {

        if (productList) {
            setLoadingData(false);
        }

        // below line needed to remove the warning on build.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [productList]);

    const getProducts = () => {
        setLoadingData(true);
        const post_data = {
            cat_id: state?.cat_id,
            date_range: state?.date_range,
        };

        GetProducts(post_data, history).then(response => {
            setProductList(response.data.products);
        });
    };

    const getCategories = () => {
        GetCategories(history).then(response => {
            if (response.status) {
                setCategories(response.data.categories);
            }
        });
    };

    return (
        <div className="content-wrapper-left flex-60 tablet-width-semifull phone-l-width-full">
            <section className="column-container">
                <div className="column-container-wrapper">
                    <div className="col-full">
                        <h1 className="content-title weight-bold uppercase default-color">
                            <FormattedMessage id="reserve" />
                        </h1>
                    </div>
                </div>
            </section>

            {/* Steps (Forms) */}

            {/* Step 1 select boats */}
            {formStep === 1 && (
                <HomeForm1
                    productList={productList}
                    loadingData={loadingData}
                    categories={categories}
                    history={history}
                    {...props} />
            )}

            {/* Step 2 select arrangements */}
            {formStep === 2 && (
                <HomeForm2
                    history={history}
                    {...props} />
            )}

            {/* register form */}
            {formStep === 3 && (
                <HomeForm3
                    history={history}
                    handleSubmit={handleSubmit}
                    submitOrder={submitOrder}
                    customerID={customerID}
                    setOpenPendingModal={setOpenPendingModal}
                    openPendingModal={openPendingModal}
                    control={control}
                    setValue={setValue}
                    watch={watch}
                    reset={reset}
                    {...props} />
            )}
        </div>
    );

};

export default HomeLeftView;