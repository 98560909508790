import React from "react";
import { Modal, Form, Table, } from 'antd';
import { FormattedMessage, } from "react-intl";

import NLFlag from '../../assets/images/flags/nl.png';
import ENFlag from '../../assets/images/flags/en.png';
import DEFlag from '../../assets/images/flags/de.png';

const DownloadsModal = ({
    modalIsOpen,
    modalIsOpenHandler,
    templates,
    id,

}) => {

    const columns = [
        {
            title: <FormattedMessage id="document-type" />,
            dataIndex: 'system_name',
            key: 'system_name',
            // align: 'center',
            // width: 125,
            render: (system_name, record) => (
                <FormattedMessage id={system_name || "UNKNOWN"} />
            )
        },
        {
            title: "Download",
            dataIndex: 'file',
            key: 'file',
            render: (system_name, record) => (
                <div>
                    {record.files.map((item, index) => (
                        <React.Fragment key={index}>
                            <span>{item.available && item.language === 'nl' && (<a href={`https://${process.env.REACT_APP_AWS_DATASTORE}/data/files/${id}/${item.file_name}`}> <img className="download-flag" src={NLFlag} alt="Download" /></a>)}</span>
                            <span>{item.available && item.language === 'en' && (<a href={`https://${process.env.REACT_APP_AWS_DATASTORE}/data/files/${id}/${item.file_name}`}> <img className="download-flag" src={ENFlag} alt="Download" /></a>)}</span>
                            <span>{item.available && item.language === 'de' && (<a href={`https://${process.env.REACT_APP_AWS_DATASTORE}/data/files/${id}/${item.file_name}`}> <img className="download-flag" src={DEFlag} alt="Download" /></a>)}</span>
                        </React.Fragment>
                    ))}
                </div>
            )
        },
    ];

    return (

        <Modal
            open={modalIsOpen}
            footer={[]}
            onCancel={() => modalIsOpenHandler(false)}>

            <Form
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                layout="vertical"
                size="large"
                className="login-form mt-4">

                <div className="login-content">
                    <p className="text-center h4">
                        <FormattedMessage id="download-documents-header" />
                    </p>

                    <Table
                        rowKey={record => record.system_name}
                        // onRow={(record, rowIndex) => {
                        //     return {
                        //         onClick: event => { clickTableRow(record) }, // click row
                        //         onDoubleClick: event => { }, // double click row
                        //         onContextMenu: event => { }, // right button click row
                        //         onMouseEnter: event => { }, // mouse enter row
                        //         onMouseLeave: event => { }, // mouse leave row
                        //     };
                        // }}
                        rowClassName="pointer"
                        dataSource={templates}
                        columns={columns}
                        pagination={false}
                    />

                </div>
            </Form>
        </Modal>
    );
};


export default DownloadsModal;
