import React, { useEffect, useState, } from "react";
import { Modal, Avatar, List, Skeleton, Button, Input, } from 'antd';
import { FormattedMessage, } from "react-intl";
import { useLocalStorage } from 'usehooks-ts';
import { Controller, useForm, useFieldArray, } from "react-hook-form";
import { LoadingOutlined, } from '@ant-design/icons';
import { Info, Minus, Plus, } from '@phosphor-icons/react';
import { GetwebshopProducts, } from '../../services/products';
import { FormatEuro, } from '../helpers';

import PlaceHolder from '../../assets/images/placeholder.png';

const ArrangementOrderModal = ({
    modalIsOpen,
    modalIsOpenHandler,
    categoryID,
    history,
    name,
    shoppingCartID,
    ...props
}) => {

    const [loading, setLoading] = useState(true);
    const [products, setProducts] = useState([]);
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const [currentDateRange, setCurrentDateRange] = useState();
    const { triggerShoppingCart, language, } = props;
    const [shoppingCart, setShoppingCart] = useLocalStorage('shoppingcart_sloep', []);
    const { control, handleSubmit, watch, } = useForm({ mode: "all" });
    useFieldArray({ control, name: "product_amount" });
    const formValues = watch();

    // check if items are already in shoppingcart for this product
    useEffect(() => {

        // getting the period
        for (let index = 0; index < shoppingCart.length; index++) {
            const element = shoppingCart[index];
            if (element.date_range) {
                setCurrentDateRange(element.date_range);
                break
            }
        };


        // const tmp_products = [];
        // for (let index = 0; index < shoppingCart.length; index++) {
        //     const element = shoppingCart[index];
        //     if (element.category_id === categoryID) {
        //         let tmp = {};
        //         tmp[element.product_data.product_id] = element.bookdetail_amount;
        //         tmp_products.push(tmp);
        //     }
        // };
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        getProducts();
        // eslint-disable-next-line
    }, [categoryID]);

    // check if the button needs to enabled or disabled
    useEffect(() => {
        if (formValues.product_amount) {
            setIsButtonDisabled(true);
            for (let i = 0; i < formValues.product_amount.length; i++) {
                const element = formValues.product_amount[i];
                for (let key in element) {
                    let value = element[key];
                    if (value > 0) {
                        setIsButtonDisabled(false);
                        break;
                    }
                };
            };
        }

    }, [formValues]);

    const getProducts = () => {
        setLoading(true);
        const post_data = {
            cat_id: categoryID,
            // date_range: state?.date_range,
        };

        GetwebshopProducts(post_data, history).then(response => {
            setProducts(response.data.products);
            setLoading(false);
        });
    };

    const renderHTMLContent = (htmlContent) =>
        React.createElement('div', {
            dangerouslySetInnerHTML: { __html: htmlContent },
        });

    const addProductToCart = (data) => {
        for (let i = 0; i < data.product_amount.length; i++) {
            const element = data.product_amount[i];
            for (let key in element) {
                let value = element[key];
                if (value > 0) {
                    const product = products.find(product => product.product_id === key);
                    processShoppingCart(product, value);
                }
            };
        }
        modalIsOpenHandler(false);
    };

    const processShoppingCart = (data, amount) => {

        // set shoppingcart object
        const shoppingcart_item = {
            product_data: data,
            product_category: [],
            bookdetail_amount: amount,
            unique_id: Math.random().toString(36).substring(2, 15),
            date_range: currentDateRange,
            days_free_of_charge: [],
            minimal_24hours_order: false,
            selected_options: [],
            category_id: categoryID,
            category_name: name,
        };

        // check if item already exists in shoppingcart
        const existingItem = shoppingCart.find(item => item.product_data.product_id === data.product_id);

        // if exist, update the amount
        if (existingItem) {
            const index = shoppingCart.indexOf(existingItem);
            if (amount === 0 || amount < 0) {
                shoppingCart.splice(index, 1);
            } else {
                shoppingCart[index].bookdetail_amount = amount;
            }

        } else {
            // create new item
            shoppingCart.push(shoppingcart_item);
        }

        setShoppingCart(shoppingCart);
        triggerShoppingCart();
    };

    return (
        <Modal
            open={modalIsOpen}
            // closable={false}
            maskClosable={false}
            width={850}
            footer={[]}
            onCancel={() => modalIsOpenHandler(false)}>

            {loading && (
                <>
                    <div className="text-center">
                        <h4><FormattedMessage id="loading-information-wait" /></h4>
                    </div>
                    <div className="spinner-container">
                        <LoadingOutlined />
                    </div>
                </>
            )}

            {!loading && (
                <section className="">
                    <div className="column-container-wrappe bg-white adius-m">
                        <div className="width-100">
                            <h2 className="content-title weight-bold default-color uppercase margin-b-xxl">
                                {name}
                            </h2>

                            <div className="width-100" id="koelbox-option-items">
                                <div
                                    className="column-container-wrapper bg-faded-tertiary padding-s radius-l margin-t-xxl margin-b-xxl">
                                    <div className="col-full display-flex note-card width-100 gap-m phone-m-display-flex">
                                        <span className="flex-5p flex-center-center">
                                            <Info size={20} className="bg-secondary white-color rounded-icon" />
                                        </span>
                                        <p className="content-description phone-m-width-semifull flex-auto">
                                            <FormattedMessage id="arrangement-order-information" />
                                        </p>
                                    </div>
                                </div>

                                <List
                                    loading={loading}
                                    itemLayout="horizontal"
                                    dataSource={products}
                                    renderItem={(item, index) => (
                                        <List.Item
                                            actions={[
                                                // <Switch size="small" key="list-switch" {...register("product_enable")} />,
                                                <Controller
                                                    key="list-input"
                                                    control={control}
                                                    defaultValue={shoppingCart.find(cart => cart.product_data.product_id === item.product_id)?.bookdetail_amount || 0}
                                                    name={`product_amount.${index}.${item.product_id}`}
                                                    rules={{
                                                        // required: { value: true, message: "inputfield-FieldIsRequired" },
                                                        // minLength: { value: 8, message: "inputfield-minLength8" },
                                                        // pattern: {
                                                        //     value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).+$/,
                                                        //     message: "PasswordMustContainsNumbersLettersUppercaseLowercase",
                                                        // },
                                                    }}
                                                    render={({
                                                        field: { onChange, onBlur, value, name, ref },
                                                        fieldState: { error },
                                                    }) => (
                                                        <Input
                                                            className="input-center"
                                                            value={value}
                                                            onChange={onChange}
                                                            size="small"
                                                            prefix={<Button type="text" size="small" onClick={e => { if (value <= 0) { onChange(0) } else { onChange(value - 1) } }} icon={<Minus size={16} />} />}
                                                            suffix={<Button type="text" size="small" onClick={e => onChange(value + 1)} icon={<Plus size={16} />} />}
                                                            style={{ width: 100 }}
                                                        />
                                                    )} />
                                            ]}>
                                            <Skeleton avatar title={false} loading={loading} active>
                                                <List.Item.Meta
                                                    avatar={<Avatar shape="square" size={100} src={item.product_images[0]?.image_s3_key ? `https://${process.env.REACT_APP_AWS_DATASTORE}/${item.product_images[0].image_s3_key}` : PlaceHolder} />}
                                                    title={<div>{item.product_names[`product_name_${language}`] || item.product_names.product_name_nl} <small><FormatEuro amount={item.product_pricing.product_pricing[0].price} /></small></div>}
                                                    description={renderHTMLContent(item.product_descriptions[`product_description_${language}`] || item.product_descriptions.description_nl)}
                                                />
                                                {/* <div>content</div> */}
                                            </Skeleton>
                                        </List.Item>
                                    )}
                                />

                            </div>
                        </div>
                    </div>

                    <div className="column-container-wrapper bg-white radius-m">
                        <div className="col-full display-flex flex-center-center">
                            <button type="button"
                                disabled={isButtonDisabled}
                                onClick={handleSubmit(addProductToCart)}
                                className="slv-button bg-secondary hover-bg-darkblue white-color weight-semibold close-popup-btn">
                                toevoegen
                            </button>
                        </div>
                    </div>
                </section>
            )}

        </Modal>
    );
};

export default ArrangementOrderModal;
