import React from "react";
import { Modal, } from 'antd';
import { FormattedMessage, } from "react-intl";


const SloopSelectorInfoModal = ({
    modalIsOpen,
    modalIsOpenHandler,
    ...props
}) => {

    return (
        <Modal
            open={modalIsOpen}
            centered={true}
            width={550}
            footer={[]}
            onCancel={() => modalIsOpenHandler(false)}>

            <section className="column-container display-flex flex-center-center">
                <p className="content-description">
                    <FormattedMessage id="contact-us-more-than-3-boats" />
                </p>
            </section>

        </Modal>
    );
};

export default SloopSelectorInfoModal;
