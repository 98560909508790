import React, { useEffect, useState } from "react";
import { Modal, Form, notification, } from 'antd';
import { FormattedMessage, useIntl, } from "react-intl";
import { useParams, } from 'react-router-dom';
import { LoadingSpinner } from '../../components/helpers';
import { useFetchAuth } from '../../services/authentication/auth';
import LoginFormView from './views/login.form.view';
import RequestPasswordFormView from './views/request.password.form.view';
import ChangePasswordFormView from './views/change.password.form';
import SignUpFormView from './views/sign-up.form.view';
import { CheckMagicLink, } from '../../services/users';

const AccountLoginModal = ({
    modalIsOpen,
    modalIsOpenHandler,
    setAuth = () => { },
    setLoadingAccountData = () => { },
    defaultForm = "LOGIN",
    history,
    processOrder,
    setValue,
    ...props
}) => {

    const { loading, auth } = useFetchAuth({ required: false });
    const [sessionData, setSessionData] = useState(undefined);
    const [loadingForm, setLoadingForm] = useState(true);
    const [loadingMagicLink, setLoadingMagicLink] = useState(true);
    const [showForm, setShowForm] = useState(defaultForm);
    const { mail, token } = useParams();
    const intl = useIntl();

    // first check if user wants to login using magic link
    // because older session could exist, distroy it frst
    useEffect(() => {
        if (token && mail) {

            //  Clear the local storage to make sure no old authentication exist.
            localStorage.removeItem('authentication_sloep');

            // validate magic link
            checkMagicLinkValidation(mail, token);

        } else {
            // otherwise check for active session
            setLoadingMagicLink(false);
            setLoadingForm(true);
            checkActiveUserSession();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mail, token, loading]);

    // set form available after the checks has finished
    useEffect(() => {
        if (!loading && !loadingMagicLink) {
            setLoadingForm(false);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading, loadingMagicLink]);

    const loadForm = () => {
        switch (showForm) {
            case "LOGIN":
                return <LoginFormView
                    setShowForm={setShowForm}
                    setSessionData={setSessionData}
                    history={history}
                    setAuth={setAuth}
                    setLoadingAccountData={setLoadingAccountData}
                    modalIsOpenHandler={modalIsOpenHandler}
                    {...props} />
            case "REQUEST_PWD":
                return <RequestPasswordFormView
                    setShowForm={setShowForm}
                    history={history}
                    modalIsOpenHandler={modalIsOpenHandler}
                    {...props} />
            case "CHANGE_PWD":
                return <ChangePasswordFormView
                    setShowForm={setShowForm}
                    sessionData={sessionData}
                    setAuth={setAuth}
                    history={history}
                    modalIsOpenHandler={modalIsOpenHandler}
                    {...props} />
            case "SIGN_UP":
            case "SIGN_UP_ONSITE":
                return <SignUpFormView
                    setShowForm={setShowForm}
                    sessionData={sessionData}
                    history={history}
                    modalIsOpenHandler={modalIsOpenHandler}
                    formType={showForm}
                    processOrder={processOrder}
                    setValue={setValue}
                    {...props}
                />
            default:
                return <LoginFormView
                    history={history}
                    modalIsOpenHandler={modalIsOpenHandler}
                    {...props} />
        };
    };

    const checkActiveUserSession = () => {
        if (!loading && auth?.authenticated) {
            history("/dashboard", { replace: true });
            modalIsOpenHandler(false);
        }
    };

    const checkMagicLinkValidation = (mail, token) => {
        if (token && mail) {

            setLoadingMagicLink(true);

            const data = {
                user_email: mail,
                token: token,
            };

            CheckMagicLink(data).then(response => {
                if (response.status) {
                    notification['success']({
                        message: 'Inloggen',
                        description: intl.formatMessage({ id: response.msg }),
                    });

                    localStorage.setItem('authentication_sloep', JSON.stringify(response.data));
                    history("/dashboard", { replace: true });
                    modalIsOpenHandler(false);

                } else {
                    notification['error']({
                        message: 'Inloggen',
                        description: intl.formatMessage({ id: response.msg }),
                    });
                    setLoadingMagicLink(false);
                }

            }).catch(error => {
                setLoadingMagicLink(false);
            })
        } else {
            setLoadingMagicLink(false);
        }
    };

    return (

        <Modal
            open={modalIsOpen}
            // title={<><ShoppingCartOutlined /> <FormattedMessage id="account-login" /></>}
            // width={showForm === "SIGN_UP_ONSITE" ? 650 : 520}
            footer={[
                // <Button key="1" onClick={() => modalIsOpenHandler(false)}><CloseOutlined /> <FormattedMessage id="close" /></Button >,
            ]}
            onCancel={() => modalIsOpenHandler(false)}>

            <Form
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                layout="vertical"
                size="large"
                className="login-form mt-4">

                <div className="login-content">
                    <p className="text-center h4"><FormattedMessage id={`${showForm.toLowerCase()}_message` || "UNKNOWN"} /></p>

                    {loadingForm ? (<LoadingSpinner />) : (loadForm())}

                </div>
            </Form>

        </Modal>
    );
};


export default AccountLoginModal;
