import * as api from '../api';

export const AddNewOrder = (data) => {
    return new Promise((resolve, reject) => {
        api
            .post('/order/new_order', data)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                console.error('error: ', error);
                reject(error);
            })
    });
};

export const AddNewOrderQuest = (data) => {
    return new Promise((resolve, reject) => {
        api
            .post('/public/new_order_quest', data)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                console.error('error: ', error);
                reject(error);
            })
    });
};

export const GetOrderList = (history) => {
    return new Promise((resolve, reject) => {
        api
            .get(`/order/order_list/me`)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                if (error.status === 403) { history('/dashboard'); }
                reject(error);
            })
    });
};

export const GetOrderDetails = (id, history) => {
    return new Promise((resolve, reject) => {
        api
            .get(`/order/customer/${id}`)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                if (error?.status === 403) { history('/dashboard'); }
                reject(error);
            })
    });
};

export const GetOrderSettings = (id, history) => {
    return new Promise((resolve, reject) => {
        api
            .get(`/settings/booking_settings`)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                if (error?.status === 403) { history('/dashboard'); }
                reject(error);
            })
    });
};

export const ListCustomerQuotes = (id, history) => {
    return new Promise((resolve, reject) => {
        api
            .get(`/order/quote_list/${id}`)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                if (error?.status === 403) { history('/dashboard'); }
                reject(error);
            })
    });
};

export const QuoteDownload = (data, history) => {
    return new Promise((resolve, reject) => {
        api
            .post(`/order/quote_download`, data)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                if (error?.status === 403) { history('/dashboard'); }
                reject(error);
            })
    });
};

export const QuoteApproval = (data, history) => {
    return new Promise((resolve, reject) => {
        api
            .post(`/order/quote_approval`, data)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                if (error?.status === 403) { history('/dashboard'); }
                reject(error);
            })
    });
};

export const CancelOrder = (booking_id, history) => {
    return new Promise((resolve, reject) => {
        api
            .remove(`/order/${booking_id}`)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                if (error?.status === 403) { history('/dashboard'); }
                reject(error);
            })
    });
};

export const ListCustomerContracts = (id, history) => {
    return new Promise((resolve, reject) => {
        api
            .get(`/order/contract_list/${id}`)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                if (error?.status === 403) { history('/dashboard'); }
                reject(error);
            })
    });
};

export const ContractDownload = (data, history) => {
    return new Promise((resolve, reject) => {
        api
            .post(`/order/contract_download`, data)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                if (error?.status === 403) { history('/dashboard'); }
                reject(error);
            })
    });
};

export const ContractApproval = (data, history) => {
    return new Promise((resolve, reject) => {
        api
            .post(`/order/contract_approval`, data)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                if (error?.status === 403) { history('/dashboard'); }
                reject(error);
            })
    });
};
