import React, { useState, } from "react";
import { Modal, Button, Form, Input, Card, notification, } from 'antd';
import { FormattedMessage, useIntl, } from "react-intl";
import { useForm, Controller, } from "react-hook-form";
import { UserAdd, CheckUserExists, } from '../../../../../services/users';
import { UserPlus, X, } from "@phosphor-icons/react";

const AddUserModal = ({
    modalIsOpen,
    modalIsOpenHandler,
    customerID,
    refreshData,
    history,
    ...props
}) => {
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const { handleSubmit, control } = useForm();
    const intl = useIntl();

    const onSubmit = (data) => {
        setButtonDisabled(true);
        const postdata = {
            "customer_id": customerID,
            "user_first_name": data.user_first_name,
            "user_last_name": data.user_last_name,
            "user_phone_number": data.user_phone_number,
            "user_email": data.user_email,
            "user_group": "users",
        };

        UserAdd(postdata)
            .then(response => {
                if (response.status) {
                    notification['success']({
                        message: 'User',
                        description: intl.formatMessage({ id: response.msg }),
                    });
                    // history('/customer/' + response.data.customer_id);
                    //close modal
                    modalIsOpenHandler(false);
                    refreshData();
                } else {
                    notification['error']({
                        message: 'User',
                        description: intl.formatMessage({ id: response.msg }),
                    });
                    setButtonDisabled(false);
                }
            })
            .catch(error => {
                console.error('error: ', error);
                setButtonDisabled(false);
                if (error.status === 403) {
                    history('/dashboard');
                    notification['error']({
                        message: 'Account',
                        description: intl.formatMessage({ id: "ENDPOINT_ACCESS_DENIED" }),
                    });
                }
            });
    };

    return (

        <Modal
            open={modalIsOpen}
            title={<><UserPlus size={18} weight="light" className="mr-1" /> <FormattedMessage id="profile-adding-new-user" /></>}
            onCancel={() => modalIsOpenHandler(false)}
            width={750}
            className="user"
            footer={[
                <Button key="1" onClick={() => modalIsOpenHandler(false)}>
                    <span><X size={18} weight="light" className="mr-1" /> <FormattedMessage id="cancel" /></span>
                </Button>,
                <Button key="2"
                    className="ant-btn-success"
                    loading={buttonDisabled}
                    onClick={handleSubmit(onSubmit)}>
                    <span>{!buttonDisabled && (<UserPlus size={18} weight="light" className="mr-1" />)} <FormattedMessage id="add" /></span>
                </Button>,
            ]}>

            <Card title={<FormattedMessage id="profile-adding-new-user-header" />}>
                <Form
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}>

                    {/* user first & Last name */}
                    <Form.Item
                        label={<FormattedMessage id="firstname-and-lastname" />}
                        required
                        style={{ marginBottom: 0 }}>

                        <FormattedMessage id="first-name">
                            {NameField =>
                                <Controller
                                    control={control}
                                    defaultValue=""
                                    name="user_first_name"
                                    rules={{ required: { value: true, message: "inputfield-FieldIsRequired" } }}
                                    render={({
                                        field: { onChange, onBlur, value, name, ref },
                                        fieldState: { error },
                                    }) => (
                                        <Form.Item
                                            help={error && intl.formatMessage({ id: error?.message })}
                                            hasFeedback
                                            validateStatus={error?.message && "error"}
                                            required
                                            style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}
                                        // label={<FormattedMessage id="firstname-and-lastname" />}
                                        >
                                            <Input
                                                placeholder={NameField}
                                                onChange={onChange}
                                                value={value}
                                            />
                                        </Form.Item>
                                    )}
                                />}
                        </FormattedMessage>

                        <FormattedMessage id="last-name">
                            {NameField =>
                                <Controller
                                    control={control}
                                    defaultValue=""
                                    name="user_last_name"
                                    rules={{ required: { value: true, message: "inputfield-FieldIsRequired" } }}
                                    render={({
                                        field: { onChange, onBlur, value, name, ref },
                                        fieldState: { error },
                                    }) => (
                                        <Form.Item
                                            help={error && intl.formatMessage({ id: error?.message })}
                                            hasFeedback
                                            validateStatus={error?.message && "error"}
                                            required
                                            style={{ display: 'inline-block', width: 'calc(50% - 8px)', margin: '0 8px' }}>
                                            <Input
                                                placeholder={NameField}
                                                onChange={onChange}
                                                value={value}
                                            />
                                        </Form.Item>
                                    )}
                                />}
                        </FormattedMessage>
                    </Form.Item>

                    {/* email address */}
                    <FormattedMessage id="emailaddress">
                        {NameField =>
                            <Controller
                                control={control}
                                defaultValue=""
                                name="user_email"
                                rules={{
                                    required: { value: true, message: "inputfield-FieldIsRequired" },
                                    pattern: {
                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,24}$/i,
                                        message: "EmailAddressNotValid",
                                    },
                                    validate: {
                                        checkemail: async (value) => {
                                            if (!value) { return true };
                                            return CheckUserExists({ user_email: value }).then(response => {
                                                if (response.data.email_exists) { return 'EmailAddressAlreadyExist' }
                                            });
                                        }
                                    }
                                }}
                                render={({
                                    field: { onChange, onBlur, value, name, ref },
                                    fieldState: { error },
                                }) => (
                                    <Form.Item
                                        help={error && intl.formatMessage({ id: error?.message })}
                                        hasFeedback
                                        validateStatus={error?.message && "error"}
                                        required
                                        label={<FormattedMessage id="emailaddress" />}>
                                        <Input
                                            placeholder={NameField}
                                            onChange={onChange}
                                            value={value}
                                        />
                                    </Form.Item>
                                )}
                            />}
                    </FormattedMessage>

                    {/* phone number */}
                    <FormattedMessage id="phonenumber">
                        {NameField =>
                            <Controller
                                control={control}
                                defaultValue=""
                                name="user_phone_number"
                                rules={{ required: { value: true, message: "inputfield-FieldIsRequired" } }}
                                render={({
                                    field: { onChange, onBlur, value, name, ref },
                                    fieldState: { error },
                                }) => (
                                    <Form.Item
                                        help={error && intl.formatMessage({ id: error?.message })}
                                        hasFeedback
                                        validateStatus={error?.message && "error"}
                                        required
                                        label={NameField}>
                                        <Input
                                            placeholder={NameField}
                                            onChange={onChange}
                                            value={value}
                                        />
                                    </Form.Item>
                                )}
                            />}
                    </FormattedMessage>



                </Form>
            </Card>
        </Modal>
    );
};

export default AddUserModal;
